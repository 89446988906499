@charset "UTF-8";
/**
 * Clay 3.124.0
 *
 * SPDX-FileCopyrightText: © 2020 Liferay, Inc. <https://liferay.com>
 * SPDX-FileCopyrightText: © 2020 Contributors to the project Clay <https://github.com/liferay/clay/graphs/contributors>
 *
 * SPDX-License-Identifier: BSD-3-Clause
 */
/**
 * Bootstrap v4.4.1
 *
 * SPDX-FileCopyrightText: © 2019 Twitter, Inc. <https://twitter.com>
 * SPDX-FileCopyrightText: © 2019 The Bootstrap Authors <https://getbootstrap.com/>
 *
 * SPDX-License-Identifier: LicenseRef-MIT-Bootstrap
 */
/**
 * Clay 3.124.0
 *
 * SPDX-FileCopyrightText: © 2020 Liferay, Inc. <https://liferay.com>
 * SPDX-FileCopyrightText: © 2020 Contributors to the project Clay <https://github.com/liferay/clay/graphs/contributors>
 *
 * SPDX-License-Identifier: BSD-3-Clause
 */
/**
 * Bootstrap v4.4.1
 *
 * SPDX-FileCopyrightText: © 2019 Twitter, Inc. <https://twitter.com>
 * SPDX-FileCopyrightText: © 2019 The Bootstrap Authors <https://getbootstrap.com/>
 *
 * SPDX-License-Identifier: LicenseRef-MIT-Bootstrap
 */
.quick-access-nav {
  position: fixed;
  z-index: 999;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.lfr-asset-column-details .lfr-asset-categories {
  color: #7d7d7d;
}
.lfr-asset-column-details .lfr-asset-categories .lfr-asset-category {
  color: #555;
}
.lfr-asset-column-details .lfr-asset-description {
  color: #444;
  font-style: italic;
  margin: 0.5em auto 2em;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}
.lfr-asset-column-details .lfr-asset-icon {
  border-right: 1px solid transparent;
  color: #999;
  float: left;
  line-height: 1em;
  margin-right: 10px;
  padding-right: 10px;
}
.lfr-asset-column-details .lfr-asset-icon.last {
  border-width: 0;
}
.lfr-asset-column-details .lfr-asset-tags {
  color: #7d7d7d;
}
.lfr-asset-column-details .lfr-asset-tags .tag {
  color: #555;
}
.lfr-asset-column-details .lfr-asset-metadata {
  clear: both;
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
}
.lfr-asset-column-details .lfr-panel {
  clear: both;
  margin-bottom: 0.2em;
}
.lfr-asset-column-details .lfr-panel.lfr-extended {
  border: 1px solid #ccc;
  margin-bottom: 0;
}
.lfr-asset-column-details .taglib-custom-attributes-list label,
.lfr-asset-column-details .lfr-asset-field label {
  display: block;
}
.lfr-asset-column-details .taglib-custom-attributes-list {
  margin-bottom: 1em;
}

.overlay {
  position: absolute;
}

.overlay-hidden,
.overlaymask-hidden {
  display: none;
}

.lfr-form-row {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 1px 5px 25px;
  position: relative;
}
.lfr-form-row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
.lfr-form-row:hover {
  border: solid #ccc;
  border-width: 1px 0;
  padding-top: 0;
}
.lfr-form-row.lfr-form-row-inline .form-group {
  margin-right: 5px;
}
.lfr-form-row legend .field-label {
  float: left;
  margin-right: 10px;
}
.lfr-form-row .handle-sort-vertical {
  background: url(../images/application/handle_sort_vertical.png) no-repeat 0 50%;
  cursor: move;
  padding-left: 20px;
}
.lfr-form-row fieldset {
  border-width: 0;
  margin: 0;
  padding: 0;
}

.lfr-autorow-controls {
  bottom: 5px;
  position: absolute;
  right: 5px;
}

.datepicker-popover-content .popover-content {
  padding: 0.75rem 1rem;
}

.dropdown.open > .dropdown-menu, .overlay-content .open > .dropdown-menu {
  display: block;
}

.form-control-inline {
  background-color: transparent;
  font-size: 1.125rem;
  font-weight: 700;
}
.form-control-inline:not(:hover) {
  border-color: transparent;
}
.form-control-inline::placeholder {
  color: #a8a9b6;
  font-style: italic;
}

.form-search .input-group {
  position: relative;
}
.form-search .input-group.advanced-search .search-query {
  padding-left: 40px;
  z-index: 0;
}
.form-search .input-group.advanced-search .toggle-advanced {
  cursor: pointer;
  left: 0;
  line-height: 1;
  padding: 8px 10px;
  position: absolute;
  top: 0;
}
.form-search .input-group.advanced-search .toggle-advanced .caret {
  margin-top: 7px;
}
.form-search .input-group.advanced-search .toggle-advanced .icon-search {
  font-size: 14px;
}
@media (max-width: 991.98px) {
  .form-search .input-group {
    width: 100%;
  }
  .form-search .input-group .btn {
    clip: rect(0, 0, 0, 0);
    left: -9999px;
    position: absolute;
  }
  .form-search .input-group input.search-query {
    width: 100%;
  }
}

.lfr-table {
  border-collapse: collapse;
  clear: both;
}
.lfr-table > tbody > tr > td,
.lfr-table > tbody > tr > th,
.lfr-table > thead > tr > td,
.lfr-table > thead > tr > th,
.lfr-table > tfoot > tr > td,
.lfr-table > tfoot > tr > th {
  padding: 0 5px;
  overflow-wrap: break-all;
  word-wrap: break-all;
}
.lfr-table > tbody > tr > td:first-child, .lfr-table > tbody > tr > td.first-child,
.lfr-table > tbody > tr > th:first-child,
.lfr-table > tbody > tr > th.first-child,
.lfr-table > thead > tr > td:first-child,
.lfr-table > thead > tr > td.first-child,
.lfr-table > thead > tr > th:first-child,
.lfr-table > thead > tr > th.first-child,
.lfr-table > tfoot > tr > td:first-child,
.lfr-table > tfoot > tr > td.first-child,
.lfr-table > tfoot > tr > th:first-child,
.lfr-table > tfoot > tr > th.first-child {
  padding-left: 0;
}
.lfr-table > tbody > tr > td:last-child, .lfr-table > tbody > tr > td.last-child,
.lfr-table > tbody > tr > th:last-child,
.lfr-table > tbody > tr > th.last-child,
.lfr-table > thead > tr > td:last-child,
.lfr-table > thead > tr > td.last-child,
.lfr-table > thead > tr > th:last-child,
.lfr-table > thead > tr > th.last-child,
.lfr-table > tfoot > tr > td:last-child,
.lfr-table > tfoot > tr > td.last-child,
.lfr-table > tfoot > tr > th:last-child,
.lfr-table > tfoot > tr > th.last-child {
  padding-right: 0;
}
.lfr-table > tbody > tr > th,
.lfr-table > thead > tr > th,
.lfr-table > tfoot > tr > th {
  font-weight: bold;
}

.lfr-pagination:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.lfr-pagination .dropdown-menu {
  display: none;
}
.lfr-pagination .dropdown-toggle .icon-caret-down {
  margin-left: 5px;
}
.lfr-pagination .lfr-pagination-controls {
  float: left;
}
.lfr-pagination .lfr-pagination-controls .search-results {
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  top: 10px;
}
.lfr-pagination .lfr-pagination-controls li .lfr-pagination-link {
  border-width: 0;
  clear: both;
  color: #333;
  display: block;
  font-weight: normal;
  line-height: 20px;
  padding: 3px 20px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}
.lfr-pagination .lfr-pagination-delta-selector {
  float: left;
}
@media (max-width: 991.98px) {
  .lfr-pagination .lfr-pagination-delta-selector .lfr-icon-menu-text {
    display: none;
  }
}
.lfr-pagination .pagination-content {
  float: right;
}

.lfr-panel.lfr-extended {
  border: 1px solid transparent;
  border-color: #dedede #bfbfbf #bfbfbf #dedede;
}
.lfr-panel.lfr-extended.lfr-collapsible .lfr-panel-button {
  display: block;
}
.lfr-panel.lfr-extended.lfr-collapsed .lfr-panel-titlebar {
  border-bottom-width: 0;
}
.lfr-panel.panel-default .panel-heading {
  border-bottom-width: 0;
}
.lfr-panel .toggler-header {
  cursor: pointer;
}

.sidebar-sm .sheet-subtitle {
  font-size: 0.75rem;
  margin-bottom: 1rem;
}
.sidebar-sm .form-control[type=file] {
  padding: 0;
}
.sidebar-sm .article-content-description .input-localized.input-localized-editor .input-group-item .wrapper .form-control {
  min-height: auto;
  padding: 0.285rem 0.75rem;
}
.sidebar-sm .list-group-item-flex {
  padding: 0.5rem 0.25rem;
}
.sidebar-sm .list-group-item-flex .autofit-col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.lfr-translation-manager {
  border-radius: 4px;
  display: inline-block;
  margin-top: 0.5em;
  min-height: 1.8em;
}
.lfr-translation-manager .lfr-translation-manager-content .lfr-translation-manager-default-locale {
  display: inline-block;
  width: auto;
}
.lfr-translation-manager .lfr-translation-manager-content .lfr-translation-manager-icon-menu {
  float: none;
  padding: 0.4em 0;
}
.lfr-translation-manager .lfr-translation-manager-content .lfr-translation-manager-icon-menu li,
.lfr-translation-manager .lfr-translation-manager-content .lfr-translation-manager-icon-menu li strong {
  display: inline;
}
.lfr-translation-manager .lfr-translation-manager-content .lfr-translation-manager-translations-message {
  margin: 10px 0;
}
.lfr-translation-manager .lfr-translation-manager-available-translations {
  white-space: normal;
}
.lfr-translation-manager .lfr-translation-manager-available-translations .lfr-translation-manager-available-translations-links {
  line-height: 1;
}
.lfr-translation-manager .lfr-translation-manager-translation {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 0.2em;
  padding: 0.4em 0.3em 0.4em 0.5em;
  text-decoration: none;
}
.lfr-translation-manager .lfr-translation-manager-translation * {
  vertical-align: middle;
}
.lfr-translation-manager .lfr-translation-manager-translation img {
  margin-right: 0.3em;
}
.lfr-translation-manager .lfr-translation-manager-translation:hover {
  background-color: #d1e5ef;
}
.lfr-translation-manager .lfr-translation-manager-translation.lfr-translation-manager-translation-editing {
  background-color: #598bec;
  border-color: #224fa8;
  color: #fff;
}
.lfr-translation-manager .lfr-translation-manager-change-default-locale {
  margin: 0 0.4em;
}
.lfr-translation-manager .lfr-translation-manager-delete-translation {
  display: inline-block;
  padding: 0 2px;
}
.lfr-translation-manager .lfr-translation-manager-delete-translation svg {
  pointer-events: none;
}

.lfr-tree a {
  text-decoration: none;
}
.lfr-tree li {
  margin-bottom: 2px;
  padding-left: 0;
}
.lfr-tree li ul li, .lfr-tree li.tree-item {
  padding-left: 0;
}
.lfr-tree li.tree-item {
  padding-left: 5px;
}
.lfr-tree li.tree-item li {
  padding-left: 20px;
}
.lfr-tree li.tree-item ul {
  margin-left: 0;
  margin-top: 5px;
}

.lfr-upload-container {
  margin-bottom: 1rem;
}
.lfr-upload-container .upload-target {
  border: 3px dashed #e5e5e9;
  margin-bottom: 1rem;
  min-height: 2rem;
  padding: 2rem 0;
  position: relative;
  text-align: center;
}
.upload-drop-intent .lfr-upload-container .upload-target {
  z-index: 100;
}
.upload-drop-active .lfr-upload-container .upload-target {
  background-color: #92e5a5;
  border-color: #69db83;
}
.lfr-upload-container .upload-target .drop-file-text {
  font-weight: normal;
}
.mobile .lfr-upload-container .upload-target .drop-file-text {
  display: none;
}
.lfr-upload-container .upload-target .small {
  display: block;
  margin: 5px 0;
  text-transform: lowercase;
}
.lfr-upload-container .manage-upload-target {
  padding-top: 5px;
  position: relative;
}
.lfr-upload-container .manage-upload-target .select-files {
  float: left;
  line-height: 0;
  margin: 0 1.125rem 1.125rem;
  padding: 0 0 0 5px;
}
.lfr-upload-container .cancel-uploads,
.lfr-upload-container .clear-uploads {
  background-repeat: no-repeat;
  float: right;
}
.lfr-upload-container .cancel-uploads {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAnFBMVEX////4YWP/dXjyS07/dXj9bXD6a234YWP4XWD2WVv2VFfsOTzoLzHmKSvkISP2VFf0TE/vREftPT/iHB72WVvvREf0TE//hon/gYX/fYD/e33/dXj/cXP9bXD/a236a23/Zmb4YWP4XWD/Wl32WVv/VVj2VFf3VFb0TE/yS072SUvvREfuQELtPT/sOTzrMzXoLzHnLC/mKSvkISPh2jkWAAAAF3RSTlMAESIiMzMzMzMzMzMzMzNERERERHd3qtw8lzkAAACOSURBVHjaZcjZDsIgEIXhcd+tu5YBKUixttS6vP+7OQKJTfxvTr4D7Tpxu/2w410SjDjwVvLQ805TPiRryfQeKM6OTI68K/BJPHGlJZJjSqSMofEOGXbJecsTgzkds58V5+J8refBU7Jx9yIrmkW0sA6gqbLyuaRjZZWtgXq58rEFan0jf3uTfRuIkf/7AO8DDcVPSSG3AAAAAElFTkSuQmCC);
  margin-right: 0;
}
.lfr-upload-container .clear-uploads {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAdVBMVEX///+6QwTEVx65RQPEVx3EWB64RQPDVxq5RAO5RAO5RQPATQ65RQO5RQO5RQPRczjJZCW9TQTLay28QgTdZAHhaAPmdyHqeQXrikDudgDvfxj0nWP1qHL2tY33tY34dgD+za7+1rj/dxH/fxj/hiH/kk3///9TM3sUAAAAFHRSTlMAbXd4eHh6gN3e3+Hi4+T5+/z8/mIsq5IAAABHSURBVBjTY2CgGWDlFuZhRuIz8cspyggxIgQ4ZFWVVCQ4EQLCCmpqalLCCAFeaWV5SVE+hAC7gJi4iCAbkqksXMK8bNRzNADCOQN++eLhCQAAAABJRU5ErkJggg==);
  padding-left: 16px;
}
.lfr-upload-container .upload-file.upload-complete.file-saved {
  padding-left: 25px;
}
.lfr-upload-container .upload-file .file-title {
  display: inline-block;
  max-width: 95%;
  overflow: hidden;
  padding-right: 16px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.lfr-upload-container .upload-file .icon-file {
  font-size: 40px;
}
.lfr-upload-container .upload-list-info {
  margin: 1em 0 0.5em;
}
.lfr-upload-container .upload-list-info .h4,
.lfr-upload-container .upload-list-info h4 {
  font-size: 1.3em;
}
.lfr-upload-container .cancel-button {
  color: #6b6c7e;
  margin-top: 1px;
  position: absolute;
  right: 5px;
  top: 50%;
  white-space: nowrap;
}
.lfr-upload-container .cancel-button .cancel-button-text {
  display: none;
  margin-left: 5px;
}
.lfr-upload-container .cancel-button:hover .cancel-button-text {
  display: inline;
}
.lfr-upload-container .cancel-button .lexicon-icon {
  height: 12px;
}
.lfr-upload-container .delete-button {
  color: #6b6c7e;
}
.lfr-upload-container .delete-button-col {
  padding-right: 10px;
}
.lfr-upload-container .file-added .success-message {
  float: right;
  font-weight: normal;
}
.lfr-upload-container .upload-error {
  opacity: 1;
  padding-left: 25px;
}
.lfr-upload-container .upload-complete .cancel-button,
.lfr-upload-container .delete-button,
.lfr-upload-container .upload-complete.file-saved .delete-button,
.lfr-upload-container .upload-complete.upload-error .delete-button {
  display: none;
}
.lfr-upload-container .multiple-files .upload-error {
  background: #f5c4c9 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAJPSURBVDjLpZPLS5RhFMYfv9QJlelTQZwRb2OKlKuINuHGLlBEBEOLxAu46oL0F0QQFdWizUCrWnjBaDHgThCMoiKkhUONTqmjmDp2GZ0UnWbmfc/ztrC+GbM2dXbv4ZzfeQ7vefKMMfifyP89IbevNNCYdkN2kawkCZKfSPZTOGTf6Y/m1uflKlC3LvsNTWArr9BT2LAf+W73dn5jHclIBFZyfYWU3or7T4K7AJmbl/yG7EtX1BQXNTVCYgtgbAEAYHlqYHlrsTEVQWr63RZFuqsfDAcdQPrGRR/JF5nKGm9xUxMyr0YBAEXXHgIANq/3ADQobD2J9fAkNiMTMSFb9z8ambMAQER3JC1XttkYGGZXoyZEGyTHRuBuPgBTUu7VSnUAgAUAWutOV2MjZGkehgYUA6O5A0AlkAyRnotiX3MLlFKduYCqAtuGXpyH0XQmOj+TIURt51OzURTYZdBKV2UBSsOIcRp/TVTT4ewK6idECAihtUKOArWcjq/B8tQ6UkUR31+OYXP4sTOdisivrkMyHodWejlXwcC38Fvs8dY5xaIId89VlJy7ACpCNCFCuOp8+BJ6A631gANQSg1mVmOxxGQYRW2nHMha4B5WA3chsv22T5/B13AIicWZmNZ6cMchTXUe81Okzz54pLi0uQWp+TmkZqMwxsBV74Or3od4OISPr0e3SHa3PX0f3HXKofNH/UIG9pZ5PeUth+CyS2EMkEqs4fPEOBJLsyske48/+xD8oxcAYPzs4QaS7RR2kbLTTOTQieczfzfTv8QPldGvTGoF6/8AAAAASUVORK5CYII=) no-repeat 5px 5px;
  border-color: #bd2130;
  color: #bd2130;
  font-weight: normal;
  margin-bottom: 16px;
  padding: 8px 8px 8px 24px;
}
.lfr-upload-container .multiple-files .upload-error .error-message {
  display: block;
}
.lfr-upload-container .multiple-files .upload-complete.file-saved .form-check-middle-left .card-body,
.lfr-upload-container .multiple-files .upload-complete.upload-error .form-check-middle-left .card-body {
  padding-left: 1rem;
}
.lfr-upload-container .multiple-files .upload-complete.file-saved .form-check-middle-left .custom-control-label,
.lfr-upload-container .multiple-files .upload-complete.upload-error .form-check-middle-left .custom-control-label {
  display: none;
}
.lfr-upload-container .single-file .upload-error {
  list-style: none;
  margin-top: 1em;
}
.lfr-upload-container .single-file .upload-error .upload-error-message {
  margin-bottom: 0.5em;
}
.lfr-upload-container .upload-complete {
  padding-left: 5px;
}
.lfr-upload-container .upload-complete .error-message,
.lfr-upload-container .upload-complete .success-message {
  font-weight: bold;
  margin-left: 1em;
}
.lfr-upload-container .upload-complete .delete-button {
  display: inline-block;
}
.lfr-upload-container .upload-complete .select-file:disabled + .custom-control-label {
  display: none;
}
.lfr-upload-container .progress {
  display: none;
  margin-top: 0.5rem;
}
.lfr-upload-container .file-uploading .progress {
  display: flex;
}

.lfr-alert-container {
  left: 0;
  position: absolute;
  right: auto;
  top: auto;
  width: 100%;
  z-index: 430;
}
.lfr-alert-container.inline-alert-container {
  position: relative;
}
.lfr-alert-container.inline-alert-container .lfr-alert-wrapper {
  padding: 0;
}
.lfr-alert-container .lfr-alert-wrapper {
  margin-bottom: 5px;
  overflow: hidden;
}

.lfr-search-container td .overlay.entry-action {
  position: static;
}
.lfr-search-container td .overlay.entry-action .btn a {
  display: block;
  padding: 4px 12px;
}

.contextual-sidebar {
  height: calc(
		100vh - var(--control-menu-container-height) - 49px
	);
  pointer-events: none;
  position: fixed;
  right: 0;
  top: calc(var(--control-menu-container-height) + 49px);
  transform: translateX(100%);
  transition: transform ease 0.5s;
  visibility: hidden;
  width: 320px;
  will-change: transform;
}
.contextual-sidebar.contextual-sidebar-visible {
  pointer-events: auto;
  transform: translateX(0);
  visibility: visible;
}

@media (min-width: 768px) {
  .contextual-sidebar {
    height: calc(
			100vh - var(--control-menu-container-height) - 64px
		);
    top: calc(
			var(--control-menu-container-height) + 64px
		);
  }
  .contextual-sidebar-content {
    transition: padding ease 0.5s;
    will-change: padding;
  }
  .contextual-sidebar-visible .contextual-sidebar-content,
  .contextual-sidebar-visible + .contextual-sidebar-content {
    padding-right: 320px;
  }
}
.taglib-diff-html div.diff-removed-image {
  background: #fdc6c6 url(../images/diff/minus.png);
}
.taglib-diff-html div.diff-added-image {
  background: #cfc url(../images/diff/plus.png) no-repeat;
}
.taglib-diff-html div.diff-removed-image, .taglib-diff-html div.diff-added-image {
  height: 300px;
  margin: 2px;
  opacity: 0.55;
  position: absolute;
  width: 200px;
}
.taglib-diff-html span.diff-html-added {
  background-color: #cfc;
  font-size: 1em;
}
.taglib-diff-html span.diff-html-added img {
  border: 2px solid #cfc;
}
.taglib-diff-html span.diff-html-changed {
  background: url(../images/diff/underline.png) bottom repeat-x;
}
.taglib-diff-html span.diff-html-changed img {
  border: 2px dotted #009;
}
.taglib-diff-html span.diff-html-removed {
  background-color: #fdc6c6;
  font-size: 1em;
  text-decoration: line-through;
}
.taglib-diff-html span.diff-html-removed img {
  border: 2px solid #fdc6c6;
}

.taglib-discussion {
  font-size: 0.875rem;
  margin-top: 1rem;
}
.taglib-discussion .actions-menu .component-action {
  display: inline-flex;
}
.taglib-discussion .button-holder {
  margin: 1rem 0;
}
.taglib-discussion .comment-container {
  margin-bottom: 1.5rem;
}
.taglib-discussion .lfr-discussion .lfr-discussion-form-edit .alloy-editor-placeholder {
  border-left-color: #dbdde1;
}
.taglib-discussion .lfr-discussion-body {
  font-size: 1rem;
}
@media (min-width: 576px) {
  .taglib-discussion .lfr-discussion-body {
    padding-left: 3.5rem;
  }
}
@media (min-width: 576px) {
  .taglib-discussion .lfr-discussion-details .taglib-user-display {
    word-wrap: break-word;
  }
}
.taglib-discussion .lfr-discussion-details .taglib-user-display .user-details .user-name {
  font-weight: bold;
  text-decoration: none;
}
.taglib-discussion .lfr-discussion-details .taglib-user-display .user-details .user-name:hover {
  text-decoration: underline;
}
.taglib-discussion .lfr-discussion-details .taglib-user-display .user-profile-image .avatar {
  background-size: 50px;
}
.taglib-discussion .lfr-discussion-container {
  border-bottom: 1px solid #e7e7ed;
  margin-bottom: 1.5rem;
}
.taglib-discussion .lfr-discussion-container:first-of-type {
  border-top: 1px solid #e7e7ed;
  padding-top: 1.5rem;
}
.taglib-discussion .lfr-discussion-container:last-of-type {
  border-bottom: 0;
}
.taglib-discussion .lfr-discussion-container .lfr-discussion {
  padding-left: 1rem;
}
.taglib-discussion .lfr-discussion-container .lfr-discussion .lfr-discussion .lfr-discussion .lfr-discussion {
  padding-left: 0;
}
@media (min-width: 576px) {
  .taglib-discussion .lfr-discussion-container .lfr-discussion {
    padding-left: 3.5rem;
  }
}
.taglib-discussion .lfr-discussion-parent-link {
  color: #67678c;
  font-weight: 600;
  margin-left: 1em;
}
@media (max-width: 767.98px) {
  .taglib-discussion .lfr-discussion-parent-link {
    display: block;
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.taglib-discussion .lfr-discussion-controls .autofit-col:first-of-type > .btn-outline-borderless {
  margin-left: -0.75rem;
}
.taglib-discussion .lfr-discussion-more-comments {
  border-top: 1px solid #e7e7ed;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.taglib-discussion .username {
  color: #272833;
  font-weight: 600;
}
.taglib-discussion .taglib-workflow-status {
  margin-left: 0.5rem;
}
.taglib-discussion .workflow-value {
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .taglib-discussion .actions-menu {
    display: none;
  }
  .taglib-discussion .comment-container:hover .actions-menu,
  .taglib-discussion .actions-menu:focus,
  .taglib-discussion .actions-menu.open {
    display: inline-block;
  }
}

.drop-zone {
  min-height: 80px;
  padding: 20px;
  text-align: center;
}
.drop-zone.drop-enabled {
  outline: 2px dashed transparent;
}
.drop-zone.drop-enabled:not(.no-border) {
  outline-color: rgba(176, 180, 187, 0.5);
}
.drop-zone p {
  margin: 10px auto 0;
  max-width: 75%;
}

.drop-here-info {
  font-size: 25px;
  height: 100%;
  left: 0;
  min-height: 100px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .drop-here-info {
    transition: none;
  }
}
.drop-here-info {
  transition: none;
}

.drop-here-info .drop-here-indicator {
  height: 115px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.drop-here-info .drop-icons {
  display: inline-block;
  position: relative;
  width: 160px;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .drop-here-info .drop-icons {
    transition: none;
  }
}
.drop-here-info .drop-icons {
  transition: none;
}

.drop-here-info .drop-icons span {
  border-radius: 3px;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .drop-here-info .drop-icons span {
    transition: none;
  }
}
.drop-here-info .drop-icons span {
  transition: none;
}

.drop-here-info .drop-icons span:nth-of-type(1) {
  box-shadow: 5px 4px 0 0 rgba(0, 0, 0, 0.08);
  height: 60px;
  left: 0;
  line-height: 60px;
  opacity: 0;
  top: 10px;
  transform: rotate(25deg);
  width: 60px;
}
.drop-here-info .drop-icons span:nth-of-type(2) {
  background: #00c2ff;
  color: #fff;
  height: 80px;
  left: 40px;
  line-height: 80px;
  width: 80px;
  z-index: 2;
}
.drop-here-info .drop-icons span:nth-of-type(3) {
  box-shadow: -5px 4px 0 0 rgba(0, 0, 0, 0.08);
  display: block;
  font-size: 45px;
  height: 70px;
  left: 90px;
  line-height: 70px;
  opacity: 0;
  position: relative;
  top: 8px;
  transform: rotate(-25deg);
  width: 70px;
  z-index: 1;
}
.drop-here-info .drop-icons span:nth-of-type(1), .drop-here-info .drop-icons span:nth-of-type(3) {
  background: #e8e6e8;
  color: #a5a6ac;
}
.drop-here-info .drop-text {
  color: #fff;
  display: block;
  font-size: 20px;
  margin-left: 0;
  margin-top: 20px;
}
.drop-active .drop-here-info {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  visibility: visible;
  z-index: 100;
}
.drop-active .drop-here-info .drop-icons span:nth-of-type(1), .drop-active .drop-here-info .drop-icons span:nth-of-type(3) {
  opacity: 1;
}
.drop-active .drop-here-info span:nth-of-type(1) {
  transform: rotate(-20deg);
}
.drop-active .drop-here-info span:nth-of-type(2) {
  transform: scale(1);
}
.drop-active .drop-here-info span:nth-of-type(3) {
  transform: rotate(15deg);
}

.taglib-empty-result-message.sheet {
  margin-bottom: 24px;
}
.taglib-empty-result-message .taglib-empty-result-message-description {
  color: #6c757d;
  margin-top: 8px;
}
.taglib-empty-result-message .taglib-empty-result-message-header,
.taglib-empty-result-message .taglib-empty-result-message-header-has-plus-btn,
.taglib-empty-result-message .taglib-empty-search-result-message-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
}
.taglib-empty-result-message .taglib-empty-result-message-header,
.taglib-empty-result-message .taglib-empty-result-message-header-has-plus-btn {
  max-width: 200px;
  padding-top: 250px;
}
.taglib-empty-result-message .taglib-empty-result-message-header,
.taglib-empty-result-message .taglib-empty-result-message-header-has-plus-btn {
  background-image: url(../images/states/empty_state.svg);
}
@media (prefers-reduced-motion: reduce) {
  .taglib-empty-result-message .taglib-empty-result-message-header,
  .taglib-empty-result-message .taglib-empty-result-message-header-has-plus-btn {
    background-image: url(../images/states/empty_state_reduced_motion.svg);
  }
}
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-empty-result-message-header,
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-empty-result-message-header-has-plus-btn {
  background-image: url(../images/states/empty_state_reduced_motion.svg);
}
.taglib-empty-result-message .taglib-empty-search-result-message-header {
  background-image: url(../images/states/search_state.svg);
  max-width: 210px;
  padding-top: 232px;
}
@media (prefers-reduced-motion: reduce) {
  .taglib-empty-result-message .taglib-empty-search-result-message-header {
    background-image: url(../images/states/search_state_reduced_motion.svg);
  }
}
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-empty-search-result-message-header {
  background-image: url(../images/states/search_state_reduced_motion.svg);
}
.taglib-empty-result-message .taglib-empty-state {
  background-image: url(../images/states/empty_state.svg);
  margin: auto;
  max-width: 250px;
  padding-top: 250px;
}
@media (prefers-reduced-motion: reduce) {
  .taglib-empty-result-message .taglib-empty-state {
    background-image: url(../images/states/empty_state_reduced_motion.svg);
  }
}
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-empty-state {
  background-image: url(../images/states/empty_state_reduced_motion.svg);
}
.taglib-empty-result-message .taglib-success-state {
  background-image: url(../images/states/success_state.svg);
  margin: auto;
  max-width: 250px;
  padding-top: 250px;
}
@media (prefers-reduced-motion: reduce) {
  .taglib-empty-result-message .taglib-success-state {
    background-image: url(../images/states/success_state_reduced_motion.svg);
  }
}
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-success-state {
  background-image: url(../images/states/success_state_reduced_motion.svg);
}
.taglib-empty-result-message .taglib-search-state {
  background-image: url(../images/states/search_state.svg);
  margin: auto;
  max-width: 250px;
  padding-top: 250px;
}
@media (prefers-reduced-motion: reduce) {
  .taglib-empty-result-message .taglib-search-state {
    background-image: url(../images/states/search_state_reduced_motion.svg);
  }
}
.c-prefers-reduced-motion .taglib-empty-result-message .taglib-search-state {
  background-image: url(../images/states/search_state_reduced_motion.svg);
}
.taglib-empty-result-message .taglib-empty-result-message-title {
  font-size: 1rem;
  margin-top: 48px;
}
.taglib-empty-result-message .taglib-empty-result-message-description {
  font-size: 0.875rem;
}
.taglib-empty-result-message .taglib-empty-result-message-header + .taglib-empty-result-message-title,
.taglib-empty-result-message .taglib-empty-search-result-message-header + .taglib-empty-result-message-title,
.taglib-empty-result-message .taglib-empty-state + .taglib-empty-result-message-title,
.taglib-empty-result-message .taglib-success-state + .taglib-empty-result-message-title,
.taglib-empty-result-message .taglib-search-state + .taglib-empty-result-message-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}
.taglib-empty-result-message .taglib-empty-result-message-header ~ .taglib-empty-result-message-description, .taglib-empty-result-message .taglib-empty-result-message-header ~ .taglib-empty-result-message-actions,
.taglib-empty-result-message .taglib-empty-search-result-message-header ~ .taglib-empty-result-message-description,
.taglib-empty-result-message .taglib-empty-search-result-message-header ~ .taglib-empty-result-message-actions,
.taglib-empty-result-message .taglib-empty-state ~ .taglib-empty-result-message-description,
.taglib-empty-result-message .taglib-empty-state ~ .taglib-empty-result-message-actions,
.taglib-empty-result-message .taglib-success-state ~ .taglib-empty-result-message-description,
.taglib-empty-result-message .taglib-success-state ~ .taglib-empty-result-message-actions,
.taglib-empty-result-message .taglib-search-state ~ .taglib-empty-result-message-description,
.taglib-empty-result-message .taglib-search-state ~ .taglib-empty-result-message-actions {
  font-size: 1rem;
  text-align: center;
}

.field-wrapper.form-inline .control-label {
  display: inline-block;
}

.field-wrapper-html .input-localized .input-group-item {
  flex-wrap: nowrap;
}

.taglib-header {
  border-bottom: 1px solid #c8c9ca;
  color: #555;
  margin-bottom: 1em;
  padding-bottom: 10px;
}
.taglib-header .header-title {
  display: inline-block;
  margin: 0.1em;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: normal;
}
.taglib-header .header-back-to {
  display: inline-block;
  vertical-align: middle;
}
.taglib-header .header-back-to a {
  display: block;
  font-weight: bold;
  padding: 0 0.3em 0 0;
  text-decoration: none;
}

.taglib-icon {
  text-decoration: none;
}
.taglib-icon:not(.btn):hover .taglib-text, .taglib-icon:not(.btn):focus .taglib-text {
  text-decoration: underline;
}
.taglib-icon[lang] > img {
  vertical-align: baseline;
  width: 16px;
}

.taglib-icon-help {
  color: #6b6c7e !important;
}
.taglib-icon-help:hover, .taglib-icon-help:active {
  color: #343a40 !important;
}

.icon-monospaced {
  color: inherit;
  display: inline-block;
  height: 2rem;
  line-height: 34px;
  text-align: center;
  width: 2rem;
}
.icon-monospaced.lexicon-icon {
  padding: 8px;
}

.taglib-icon-list li {
  float: left;
  margin-right: 1em;
}
.taglib-icon-list:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

.lfr-icon-menu .lfr-icon-menu-icon {
  max-width: none;
}
@media (max-width: 767.98px) {
  .table-cell.last .lfr-icon-menu .dropdown-toggle {
    text-align: center;
  }
  .table-cell.last .lfr-icon-menu .dropdown-toggle > img,
  .table-cell.last .lfr-icon-menu .dropdown-toggle .lfr-icon-menu-text {
    display: none;
  }
}

.lfr-menu-list {
  float: none;
  overflow-y: auto;
  position: relative;
}
.lfr-menu-list.direction-right {
  margin: 0 2px;
}
.lfr-menu-list.dropdown-menu {
  float: none;
  position: relative;
}
@media (max-width: 991.98px) {
  .lfr-menu-list.dropdown-menu > li > a {
    font-size: 22px;
    line-height: 40px;
    white-space: normal;
  }
  .lfr-menu-list.dropdown-menu > li > a img {
    margin-bottom: 3px;
  }
}
.lfr-menu-list.dropdown-menu .search-panel {
  margin-bottom: 0;
}
.lfr-menu-list.dropdown-menu .search-panel .form-group {
  margin: 0 16px;
}
.lfr-menu-list.dropdown-menu .search-panel .menu-item-filter {
  width: auto;
}
body > .lfr-menu-list ul {
  border-radius: 4px;
}

.lfr-icon-menu-open:after {
  clear: both;
  content: "";
  display: table;
}
.lfr-icon-menu-open .dropdown-menu {
  position: static;
}

.input-localized.input-localized-editor .form-control {
  border-width: 0;
  height: 100%;
  padding: 0;
}
.input-localized .input-group-item {
  flex-direction: column;
}
.input-localized .input-localized-content {
  margin-left: 6px;
}
.input-group .input-localized .input-localized-content {
  margin-top: 3px;
}
.input-localized .lfr-input-localized .lfr-input-localized-state {
  background-color: #ddd;
}
.input-localized .lfr-input-localized .lfr-input-localized-state.lfr-input-localized-state-error {
  background-color: #ff0047;
}
.input-localized .lfr-input-localized-default a {
  margin-right: 14px;
}
.input-localized .lfr-input-localized-state {
  height: 4px;
  margin: 3px 5px;
  width: 4px;
}
.input-localized .palette-item-inner .lfr-input-localized-flag {
  font-size: 16px;
  opacity: 0.5;
}
.input-localized .palette-item,
.input-localized .palette-item-hover,
.input-localized .palette-item-hover .palette-item-inner {
  border-color: transparent;
}
.input-localized .palette-item-inner {
  border-color: #666;
  display: block;
  height: 16px;
  width: 16px;
}
.input-localized .palette-item-selected .lfr-input-localized-state {
  background: #27c1f2;
}

.input-localized-palette-container .palette-items-container {
  font-size: 0;
  margin: 0;
  white-space: normal;
}
.input-localized-palette-container .palette-item {
  border-width: 0;
  padding: 0.5rem 1.25rem;
}
.input-localized-palette-container .palette-item-inner {
  border: 1px solid;
  margin-right: 4px;
  width: 16px;
}
.input-localized-palette-container .palette-item-inner img {
  display: block;
}

.taglib-move-boxes {
  margin-bottom: 1em;
}
.taglib-move-boxes label {
  border-bottom: 1px solid transparent;
  display: block;
  margin-bottom: 5px;
  padding: 5px;
}
.taglib-move-boxes .toolbar {
  text-align: center;
}
.taglib-move-boxes .arrow-button img {
  border-width: 0;
  height: 16px;
  width: 16px;
}
.taglib-move-boxes .choice-selector {
  width: 100%;
}
.taglib-move-boxes .choice-selector label {
  background: #ebf1f9;
  border-bottom-color: #8db2f3;
}
.taglib-move-boxes .field-content {
  margin-bottom: 0;
}
.taglib-move-boxes .move-arrow-buttons {
  margin-top: 5em;
}
.taglib-move-boxes .move-arrow-buttons .arrow-button {
  display: block;
}
.taglib-move-boxes .sortable-container {
  margin-top: 10px;
}
.taglib-move-boxes .sortable-container .btn.edit-selection {
  margin-bottom: 10px;
}
.taglib-move-boxes .sortable-container .move-option {
  background-color: transparent;
  border-top: 1px solid #ddd;
  display: none;
  margin: 2px 0;
  padding: 2px;
  position: relative;
}
.taglib-move-boxes .sortable-container .move-option.selected {
  display: block;
}
.taglib-move-boxes .sortable-container .move-option.move-option-dragging {
  background-color: #fafafa;
}
.taglib-move-boxes .sortable-container .move-option .checkbox,
.taglib-move-boxes .sortable-container .move-option .handle {
  position: absolute;
}
.taglib-move-boxes .sortable-container .move-option .checkbox {
  display: none;
  margin: 7px 0 0 5px;
}
.taglib-move-boxes .sortable-container .move-option .handle {
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  padding: 6px;
}
.taglib-move-boxes .sortable-container .move-option .title {
  font-size: 1.2em;
  margin: 1px 0 1px 30px;
}
.taglib-move-boxes .sortable-container.edit-list-active .move-option {
  display: block;
}
.taglib-move-boxes .sortable-container.edit-list-active .move-option .checkbox {
  display: inline-block;
}
.taglib-move-boxes .sortable-container.edit-list-active .move-option .handle {
  display: none;
}
.mobile .taglib-move-boxes .selector-container {
  display: none;
}

.item-selector .card-row > .autofit-col-expand {
  padding-top: 4px;
}
.item-selector .drop-zone {
  background-color: #f1f2f5;
  margin-top: 1rem;
}
.item-selector .input-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}
.item-selector .input-file + label {
  position: relative;
}
.item-selector .input-file + label::before {
  border-radius: 4px;
  bottom: -2px;
  content: "";
  display: block;
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: box-shadow 0.15s ease-in-out;
}
.item-selector .input-file:focus + label::before {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #80acff;
}
.item-selector .item-selector-list-row:hover {
  background-color: #f0f5ff;
  cursor: pointer;
}

.lfr-map {
  border: 1px solid #ccc;
  min-height: 400px;
  width: 100%;
}
.lfr-map .home-button {
  margin: 5px;
}
@media (max-width: 575.98px) {
  .lfr-map .home-button {
    height: 35px;
    width: 45px;
  }
}
.lfr-map .search-controls {
  font-size: 15px;
  margin-top: 5px;
  width: 100%;
}
@media (min-width: 576px) {
  .lfr-map .search-controls {
    width: 50%;
  }
}
.lfr-map .search-controls .search-input {
  width: 100%;
}

.navbar .navbar-collapse {
  max-height: none;
}
.navbar .navbar-collapse:after, .navbar .navbar-collapse:before {
  display: inline;
}
@media (max-width: 767.98px) {
  .navbar .navbar-search {
    background-color: #f3f3f3;
    border-top-width: 0;
    width: 100%;
  }
}
.navbar .navbar-search .form-search {
  margin: 8px 0;
}
@media (min-width: 768px) {
  .navbar .navbar-search .form-search {
    float: right;
    max-width: 275px;
  }
}
@media (max-width: 767.98px) {
  .navbar .navbar-search .form-search .input-group {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar .container .navbar-btn,
  .navbar .container-fluid .navbar-btn {
    margin-bottom: 5px;
    width: 45px;
  }
  .navbar .container .navbar-collapse.open,
  .navbar .container-fluid .navbar-collapse.open {
    border-width: 0;
    display: block;
    height: auto;
    overflow: visible;
  }
}

.taglib-portlet-preview.show-borders {
  border: 1px solid #828f95;
  margin-bottom: 1em;
  padding: 3px 3px 1em;
}
.taglib-portlet-preview.show-borders .title {
  background-color: #d3dadd;
  font-size: 1.4em;
  font-weight: bold;
  padding: 0.5em;
}
.taglib-portlet-preview .preview {
  margin: 1em;
  min-height: 90px;
}

.lfr-search-container .entry-action {
  width: 1px;
}
.lfr-search-container .entry-action .taglib-icon {
  white-space: nowrap;
}

.lfr-search-container-wrapper.lfr-search-container-fixed-first-column {
  position: relative;
}
@media (min-width: 576px) {
  .lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive {
    margin-left: 375px;
    width: auto;
  }
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table {
  position: static;
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header {
  left: 12px;
  position: fixed;
  right: 12px;
  top: -1px;
  z-index: 1020;
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header > th {
  display: block;
  padding: 0;
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header > th .lfr-search-iterator-fixed-header-inner-wrapper {
  overflow-x: hidden;
}
@media (min-width: 576px) {
  .lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header > th .lfr-search-iterator-fixed-header-inner-wrapper {
    margin-left: 375px;
  }
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header > th .lfr-search-iterator-fixed-header-inner-wrapper table {
  border-collapse: collapse;
  width: 100%;
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table .lfr-search-iterator-fixed-header > th .lfr-search-iterator-fixed-header-inner-wrapper table th {
  border-radius: 0;
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table td,
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table th {
  width: auto;
}
@media (min-width: 576px) {
  .lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table td:first-child,
  .lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table th:first-child {
    background-color: #fff;
    left: 0;
    position: absolute;
    right: 15px;
  }
}
.lfr-search-container-wrapper.lfr-search-container-fixed-first-column .table-responsive .table th {
  height: auto;
}
.lfr-search-container-wrapper a:not(.btn):not(.component-action):not(.page-link) {
  color: #495057;
  font-weight: 500;
  text-decoration: underline;
}
.lfr-search-container-wrapper a:not(.btn):not(.component-action):not(.page-link):focus, .lfr-search-container-wrapper a:not(.btn):not(.component-action):not(.page-link):hover {
  color: #000;
}
.lfr-search-container-wrapper .dropdown-action .dropdown-toggle {
  color: #6b6c7e;
}
.lfr-search-container-wrapper .dropdown-action .dropdown-toggle:hover {
  color: #343a40;
}
.lfr-search-container-wrapper .lfr-icon-menu > .dropdown-toggle {
  color: #6b6c7e;
}
.lfr-search-container-wrapper .lfr-icon-menu > .dropdown-toggle:active, .lfr-search-container-wrapper .lfr-icon-menu > .dropdown-toggle:focus, .lfr-search-container-wrapper .lfr-icon-menu > .dropdown-toggle:hover {
  background-color: #f7f8f9;
  border-radius: 4px;
  color: #343a40;
}
.lfr-search-container-wrapper .lfr-search-container-list .list-group:last-child .list-group-item:nth-last-child(2) {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.lfr-search-container-wrapper .lfr-search-container-list + .taglib-search-iterator-page-iterator-bottom {
  margin-top: 20px;
}
.lfr-search-container-wrapper .list-group {
  margin-bottom: 0;
}
.lfr-search-container-wrapper .list-group + .list-group .list-group-header:first-child,
.lfr-search-container-wrapper .list-group + .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.lfr-search-container-wrapper .list-group .list-group-item .h4,
.lfr-search-container-wrapper .list-group .list-group-item h4 {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0;
}
.lfr-search-container-wrapper .list-group .list-group-item .h5,
.lfr-search-container-wrapper .list-group .list-group-item .h6,
.lfr-search-container-wrapper .list-group .list-group-item h5,
.lfr-search-container-wrapper .list-group .list-group-item h6 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}
.lfr-search-container-wrapper .list-group .list-group-item span + h2.h5 {
  font-weight: 600;
}
.lfr-search-container-wrapper .list-group .list-group-item .h6 + .h5,
.lfr-search-container-wrapper .list-group .list-group-item h6 + h5 {
  font-weight: 600;
}
.lfr-search-container-wrapper .table-list tbody tr:nth-last-child(2) td:first-child,
.lfr-search-container-wrapper .table-list tbody tr:nth-last-child(2) th:first-child {
  border-bottom-left-radius: 0.25rem;
}
.lfr-search-container-wrapper .table-list tbody tr:nth-last-child(2) td:last-child,
.lfr-search-container-wrapper .table-list tbody tr:nth-last-child(2) th:last-child {
  border-bottom-right-radius: 0.25rem;
}

.user-info {
  display: flex;
}
.user-info .sticker {
  align-self: center;
}
.user-info .user-details {
  margin-left: 1rem;
}
.user-info .user-name {
  color: #343a40;
  font-weight: 600;
}
.user-info .date-info {
  color: #6b6c7e;
}

.user-status-tooltip .user-status-avatar {
  float: left;
  margin-right: 5px;
}
.user-status-tooltip .user-status-avatar .user-status-avatar-image {
  width: 27px;
}
.user-status-tooltip .user-status-info {
  display: inline-block;
  overflow: hidden;
}
.user-status-tooltip .user-status-info .user-status-date {
  font-size: 11px;
}

.lfr-search-container {
  margin-top: 1.5em;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .lfr-search-container .selector-button {
    width: auto;
  }
}
.touch .lfr-search-container {
  -webkit-overflow-scrolling: touch;
}
.touch .lfr-search-container .searchcontainer-content .table {
  max-width: none;
}

.taglib-page-iterator {
  clear: both;
  height: auto;
  width: auto;
}
.taglib-page-iterator .lfr-pagination-buttons {
  float: right;
  margin: 0;
}
@media (max-width: 991.98px) {
  .taglib-page-iterator .lfr-pagination-buttons {
    width: 100%;
  }
}
@media (max-width: 991.98px) and (max-width: 767.98px) {
  .taglib-page-iterator .lfr-pagination-buttons {
    float: none;
    margin-top: 20px;
  }
}
@media (max-width: 991.98px) {
  .taglib-page-iterator .lfr-pagination-buttons > li {
    display: inline-block;
    width: 50%;
  }
  .taglib-page-iterator .lfr-pagination-buttons > li.first, .taglib-page-iterator .lfr-pagination-buttons > li.last {
    display: none;
  }
  .taglib-page-iterator .lfr-pagination-buttons > li > a {
    line-height: 20px;
    padding: 11px 19px;
  }
}
.taglib-page-iterator .lfr-pagination-config {
  float: left;
  line-height: 46px;
}
@media (max-width: 767.98px) {
  .taglib-page-iterator .lfr-pagination-config {
    float: none;
  }
  .taglib-page-iterator .lfr-pagination-config .current-page-menu {
    display: block;
  }
  .taglib-page-iterator .lfr-pagination-config .current-page-menu .btn {
    display: block;
  }
}
.taglib-page-iterator .lfr-pagination-config .lfr-pagination-delta-selector,
.taglib-page-iterator .lfr-pagination-config .lfr-pagination-page-selector {
  display: inline;
}
.taglib-page-iterator .lfr-pagination-config .lfr-pagination-delta-selector {
  float: none;
}
@media (max-width: 991.98px) {
  .taglib-page-iterator .lfr-pagination-config .lfr-pagination-delta-selector {
    display: none;
  }
}
.taglib-page-iterator .search-results {
  float: left;
  line-height: 46px;
  margin-left: 10px;
}
@media (max-width: 991.98px) {
  .taglib-page-iterator .search-results {
    display: none;
  }
}

@media (max-width: 991.98px) {
  ul.pagination ul.inline-scroller {
    max-height: inherit;
  }
}
.taglib-search-toggle .form-search {
  position: relative;
}
.taglib-search-toggle .form-search .input-group-btn {
  position: absolute;
}
@media (min-width: 992px) {
  .taglib-search-toggle .form-search .input-group-btn {
    position: static;
  }
}
.taglib-search-toggle .toggle-advanced {
  color: inherit;
  margin-left: 10px;
  position: absolute;
  top: 8px;
}
.taglib-search-toggle .toggle-advanced, .taglib-search-toggle .toggle-advanced:hover, .taglib-search-toggle .toggle-advanced:focus {
  text-decoration: none;
}

.taglib-search-toggle-advanced-wrapper .taglib-search-toggle-advanced {
  background-color: #fcfcfc;
  border: solid #ddd;
  border-width: 0 1px 1px;
  margin-top: 0;
  padding: 15px 15px 0;
}
.navbar-search .taglib-search-toggle-advanced-wrapper .taglib-search-toggle-advanced {
  border-width: 1px 0 0;
}
.taglib-search-toggle-advanced-wrapper .taglib-search-toggle-advanced .taglib-search-toggle-advanced-content {
  position: relative;
}
.taglib-search-toggle-advanced-wrapper .taglib-search-toggle-advanced .taglib-search-toggle-advanced-content .match-fields {
  margin-bottom: 0;
}
.taglib-search-toggle-advanced-wrapper .taglib-search-toggle-advanced .taglib-search-toggle-advanced-content .match-fields-legend {
  color: #999;
  font-size: 13px;
}
.taglib-search-toggle-advanced-wrapper .btn.close {
  margin-right: 5px;
  position: relative;
  z-index: 1;
}

.taglib-user-display {
  padding: 0.5em;
}
.taglib-user-display .avatar {
  background: no-repeat center;
  background-size: 60px;
  display: block;
  height: 60px;
  margin: 0 auto;
  width: 60px;
}
.taglib-user-display .avatar.author:after {
  background: rgba(50, 168, 230, 0.5);
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}
.taglib-user-display .user-details {
  margin-top: 1em;
}
.taglib-user-display .user-name {
  font-size: 1.1em;
  font-weight: bold;
}
.taglib-user-display a .user-name {
  text-decoration: underline;
}
.taglib-user-display.display-style-1 .user-profile-image {
  float: left;
  margin-right: 24px;
}
.taglib-user-display.display-style-1 .user-name {
  display: inline-block;
  margin-top: 10px;
}
.taglib-user-display.display-style-1 .user-details {
  margin-top: 0;
}
.taglib-user-display.display-style-2 .user-profile-image,
.taglib-user-display.display-style-2 .user-name {
  clear: both;
  display: block;
  min-height: 20px;
  text-align: center;
}
.taglib-user-display.display-style-3 {
  padding: 0;
}
.taglib-user-display.display-style-3 .user-profile-image {
  display: inline-block;
  vertical-align: middle;
}

.taglib-workflow-status .workflow-id,
.taglib-workflow-status .workflow-version,
.taglib-workflow-status .workflow-status {
  color: #999;
}
.table-cell .taglib-workflow-status {
  margin: 0;
}
.table-cell .taglib-workflow-status .workflow-status {
  padding-left: 0;
}

.lfr-autocomplete-input-list .yui3-aclist-list {
  margin: 0;
}

.portal-popup .sheet > .lfr-nav {
  margin-top: -24px;
}
.portal-popup .contacts-portlet .portlet-configuration-container .form {
  position: static;
}
.portal-popup .lfr-form-content {
  padding: 24px 12px;
}
.portal-popup .portlet-body,
.portal-popup .portlet-boundary,
.portal-popup .portlet-column,
.portal-popup .portlet-layout {
  height: 100%;
}
.portal-popup .portlet-column {
  position: static;
}
.portal-popup .dialog-body > .container-fluid-max-xl,
.portal-popup .dialog-body .container-view,
.portal-popup .export-dialog-tree > .container-fluid-max-xl,
.portal-popup .export-dialog-tree .container-view,
.portal-popup .lfr-dynamic-uploader > .container-fluid-max-xl,
.portal-popup .lfr-dynamic-uploader .container-view,
.portal-popup .lfr-form-content > .container-fluid-max-xl,
.portal-popup .lfr-form-content .container-view,
.portal-popup .portlet-configuration-body-content > .container-fluid-max-xl,
.portal-popup .portlet-configuration-body-content .container-view,
.portal-popup .process-list > .container-fluid-max-xl,
.portal-popup .process-list .container-view,
.portal-popup .roles-selector-body > .container-fluid-max-xl,
.portal-popup .roles-selector-body .container-view {
  padding-top: 20px;
}
.portal-popup .dialog-body > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .dialog-body .container-view .nav-tabs-underline,
.portal-popup .export-dialog-tree > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .export-dialog-tree .container-view .nav-tabs-underline,
.portal-popup .lfr-dynamic-uploader > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .lfr-dynamic-uploader .container-view .nav-tabs-underline,
.portal-popup .lfr-form-content > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .lfr-form-content .container-view .nav-tabs-underline,
.portal-popup .portlet-configuration-body-content > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .portlet-configuration-body-content .container-view .nav-tabs-underline,
.portal-popup .process-list > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .process-list .container-view .nav-tabs-underline,
.portal-popup .roles-selector-body > .container-fluid-max-xl .nav-tabs-underline,
.portal-popup .roles-selector-body .container-view .nav-tabs-underline {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -20px;
}
.portal-popup .dialog-body > .lfr-nav + .container-fluid-max-xl,
.portal-popup .export-dialog-tree > .lfr-nav + .container-fluid-max-xl,
.portal-popup .lfr-dynamic-uploader > .lfr-nav + .container-fluid-max-xl,
.portal-popup .lfr-form-content > .lfr-nav + .container-fluid-max-xl,
.portal-popup .portlet-configuration-body-content > .lfr-nav + .container-fluid-max-xl,
.portal-popup .process-list > .lfr-nav + .container-fluid-max-xl,
.portal-popup .roles-selector-body > .lfr-nav + .container-fluid-max-xl {
  padding-top: 0;
}
.portal-popup .login-container {
  padding: 1rem;
}
.portal-popup .navbar ~ .portlet-configuration-setup,
.portal-popup .portlet-export-import-container {
  height: calc(100% - 48px);
  position: relative;
}
@media (min-width: 576px) {
  .portal-popup .navbar ~ .portlet-configuration-setup,
  .portal-popup .portlet-export-import-container {
    height: calc(100% - 48px);
  }
}
.portal-popup .panel-group .panel {
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
}
.portal-popup .panel-group .panel + .panel {
  border-top-width: 0;
  margin-top: 0;
}
.portal-popup .panel-heading {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.portal-popup .portlet-configuration-setup .lfr-nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 3px;
  padding-right: 3px;
}
@media (min-width: 576px) {
  .portal-popup .portlet-configuration-setup .lfr-nav {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.portal-popup .lfr-dynamic-uploader,
.portal-popup .process-list {
  bottom: 0;
  display: block;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 48px;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 576px) {
  .portal-popup .lfr-dynamic-uploader,
  .portal-popup .process-list {
    top: 48px;
  }
}
.portal-popup .portlet-export-import-publish-processes {
  top: 0;
}
.portal-popup .dialog-footer {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  left: 0;
  margin: 0;
  padding: 10px 24px;
  width: 100%;
  z-index: 1020;
}
@media (min-width: 768px) {
  .portal-popup .dialog-footer {
    position: fixed;
  }
}
.portal-popup .dialog-footer .btn {
  margin-left: 1rem;
  margin-right: 0;
}
.portal-popup .dialog-footer.dialog-footer-not-reverse {
  flex-direction: initial;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .portal-popup .dialog-body:not(:last-child),
  .portal-popup .lfr-dynamic-uploader:not(:last-child),
  .portal-popup .lfr-form-content:not(:last-child),
  .portal-popup .portlet-configuration-body-content:not(:last-child),
  .portal-popup .roles-selector-body:not(:last-child) {
    padding-bottom: 60px;
  }
}
.portal-popup .lfr-dynamic-uploader {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.portal-popup .lfr-dynamic-uploader.hide-dialog-footer {
  bottom: 0;
}
.portal-popup .lfr-dynamic-uploader.hide-dialog-footer + .dialog-footer {
  display: none;
}
.portal-popup .portlet-configuration-edit-permissions .portlet-configuration-body-content {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.portal-popup .portlet-configuration-edit-permissions .portlet-configuration-body-content > form {
  flex-grow: 1;
  max-width: none;
  overflow: auto;
}
.portal-popup .portlet-configuration-edit-templates .portlet-configuration-body-content {
  bottom: 0;
}
.portal-popup:not(.article-preview) #main-content,
.portal-popup:not(.article-preview) #wrapper {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
}
@media print {
  .portal-popup:not(.article-preview) #main-content,
  .portal-popup:not(.article-preview) #wrapper {
    position: initial;
  }
}
.portal-popup .columns-max > .portlet-layout.row {
  margin-left: 0;
  margin-right: 0;
}
.portal-popup .columns-max > .portlet-layout.row > .portlet-column {
  padding-left: 0;
  padding-right: 0;
}

html:not(#__):not(#___) .portlet-layout.dragging {
  border-collapse: separate;
}
html:not(#__):not(#___) .drop-area {
  background-color: #d3dadd;
}
html:not(#__):not(#___) .active-area {
  background: #ffc;
}
html:not(#__):not(#___) .portlet-boundary.yui3-dd-dragging {
  opacity: 0.6;
}
html:not(#__):not(#___) .portlet-boundary.yui3-dd-dragging .portlet {
  border: 2px dashed #ccc;
}
html:not(#__):not(#___) .sortable-layout-proxy {
  opacity: 1;
}
html:not(#__):not(#___) .sortable-layout-proxy .portlet-topper {
  background-image: none;
}
html:not(#__):not(#___) .proxy {
  cursor: move;
  opacity: 0.65;
  position: absolute;
}
html:not(#__):not(#___) .proxy.generic-portlet {
  height: 200px;
  width: 300px;
}
html:not(#__):not(#___) .proxy.generic-portlet .portlet-title {
  padding: 10px;
}
html:not(#__):not(#___) .proxy.not-intersecting .forbidden-action {
  background: url(../images/application/forbidden_action.png) no-repeat;
  display: block;
  height: 32px;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 32px;
}
html:not(#__):not(#___) .resizable-proxy {
  border: 1px dashed #828f95;
  position: absolute;
  visibility: hidden;
}
html:not(#__):not(#___) .sortable-proxy {
  background: #727c81;
  margin-top: 1px;
}
html:not(#__):not(#___) .sortable-layout-drag-target-indicator {
  margin: 2px 0;
}
html:not(#__):not(#___) .yui3-dd-proxy {
  z-index: 1110 !important;
}

.portlet-layout.dragging {
  border-collapse: separate;
}

.drop-area {
  background-color: #d3dadd;
}

.active-area {
  background: #ffc;
}

.portlet-boundary.yui3-dd-dragging {
  opacity: 0.6;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .portlet-boundary.yui3-dd-dragging {
    transition: none;
  }
}
.c-prefers-reduced-motion .portlet-boundary.yui3-dd-dragging {
  transition: none;
}
.portlet-boundary.yui3-dd-dragging .portlet {
  border: 2px dashed #ccc;
}

.sortable-layout-proxy {
  opacity: 1;
}
.sortable-layout-proxy .portlet-topper {
  background-image: none;
}

.proxy {
  cursor: move;
  opacity: 0.65;
  position: absolute;
}
.proxy.generic-portlet {
  height: 200px;
  width: 300px;
}
.proxy.generic-portlet .portlet-title {
  padding: 10px;
}
.proxy.not-intersecting .forbidden-action {
  background: url(../images/application/forbidden_action.png) no-repeat;
  display: block;
  height: 32px;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 32px;
}

.resizable-proxy {
  border: 1px dashed #828f95;
  position: absolute;
  visibility: hidden;
}

.sortable-proxy {
  background: #727c81;
  margin-top: 1px;
}

.sortable-layout-drag-target-indicator {
  margin: 2px 0;
}

.yui3-dd-proxy {
  z-index: 1110 !important;
}

.portlet-column-content.empty {
  padding: 50px;
}

.lfr-portlet-title-editable {
  margin-top: 0;
  z-index: 9999;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content {
  padding: 0;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content .field-input {
  margin-bottom: 0;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content .textfield-label {
  display: none;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content .btn-toolbar-content {
  display: inline-block;
  vertical-align: bottom;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content .btn-group {
  margin-top: 0;
}
.lfr-portlet-title-editable .lfr-portlet-title-editable-content .btn {
  display: inline-block;
  float: none;
  margin-top: 0;
  width: auto;
}

.lfr-source-editor {
  border: solid 0 #ccc;
  border-bottom-width: 2px;
  position: relative;
}
.lfr-source-editor .ace_editor {
  height: 100%;
}
.lfr-source-editor .lfr-source-editor-toolbar li > .btn {
  background-color: #fff;
  border: transparent;
  color: #717383;
  outline: 0;
}
.lfr-source-editor .lfr-source-editor-code {
  background-color: #fff;
  color: #2b4259;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter {
  background-color: #ededef;
  color: #868896;
  overflow: hidden;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_fold-widget {
  font-family: fontawesome-alloy;
  text-align: center;
  vertical-align: middle;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_fold-widget.ace_open, .lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_fold-widget.ace_closed {
  background-image: none;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_fold-widget.ace_open:before {
  content: "▾";
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_fold-widget.ace_closed:before {
  content: "▸";
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_gutter-active-cell {
  color: #fff;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_gutter-active-line {
  background-color: #717383;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_gutter-layer {
  border-right: solid 1px #ccc;
}
.lfr-source-editor .lfr-source-editor-code .ace_gutter .ace_info {
  background-image: none;
}
.lfr-source-editor .lfr-source-editor-code .ace_content .ace_active-line {
  background-color: #ededef;
}
.lfr-source-editor .lfr-source-editor-code .ace_content .ace_constant {
  color: #34adab;
}
.lfr-source-editor .lfr-source-editor-code .ace_content .ace_tag {
  color: #1d5ec7;
}
.lfr-source-editor .lfr-source-editor-code .ace_content .ace_string {
  color: #ff6c58;
}
.lfr-source-editor .lfr-source-editor-code .ace_content .ace_string.ace_regex {
  color: #f00;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code {
  background-color: #47474f;
  color: #fff;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code .ace_gutter {
  background: #54555e;
  color: #fff;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code .ace_gutter .ace_gutter-active-line {
  background-color: #009aed;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code .ace_content .ace_active-line {
  background-color: #11394e;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code .ace_content .ace_cursor {
  color: #fff;
}
.lfr-source-editor.ace_dark .lfr-source-editor-code .ace_content .ace_tag {
  color: #4d91ff;
}

.lfr-fullscreen-source-editor {
  height: 100%;
  overflow: hidden;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-header {
  height: 40px;
  margin-right: 4px;
  margin-top: 4px;
  min-height: 40px;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content {
  height: 95%;
  position: relative;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content .panel-splitter {
  border: 1px solid #ccc;
  position: absolute;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content .preview-panel {
  display: inline-block;
  overflow-y: auto;
  padding-left: 20px;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content .source-panel {
  display: inline-block;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.vertical .source-panel,
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.vertical .preview-panel {
  height: 100%;
  width: 50%;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.vertical .panel-splitter {
  height: 100%;
  left: 50%;
  top: 0;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.horizontal .source-panel,
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.horizontal .preview-panel {
  height: 50%;
  width: 100%;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.horizontal .panel-splitter {
  top: 50%;
  width: 100%;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.simple .panel-splitter,
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.simple .preview-panel {
  display: none;
}
.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-content.simple .source-panel {
  height: 100%;
  width: 100%;
}

.lfr-fulscreen-source-editor-dialog .modal-footer {
  text-align: left;
}

.file-icon-color-0 {
  background-color: #fff;
  color: #6c757d;
}

.file-icon-color-1 {
  background-color: #fff;
  color: #6c757d;
}

.file-icon-color-2 {
  background-color: #fff;
  color: #24a892;
}

.file-icon-color-3 {
  background-color: #fff;
  color: #ff4d4d;
}

.file-icon-color-4 {
  background-color: #fff;
  color: #ff6200;
}

.file-icon-color-5 {
  background-color: #fff;
  color: #bf66ff;
}

.file-icon-color-6 {
  background-color: #fff;
  color: #0099e6;
}

.file-icon-color-7 {
  background-color: #fff;
  color: #ff4db2;
}

.lfr-item-viewer.uploading > div:not(.progress-container) {
  opacity: 0.3;
}
.lfr-item-viewer.uploading > .progress-container {
  visibility: visible;
}
.lfr-item-viewer .aspect-ratio a.item-preview {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}
.lfr-item-viewer .image-viewer-base-image-list {
  padding-top: 35px;
}
.lfr-item-viewer .item-preview:hover {
  cursor: pointer;
}
.lfr-item-viewer .progress-container {
  background-color: #fff;
  left: 0;
  margin: 0 auto;
  padding: 20px 30px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: 80%;
  z-index: 1000;
}
.lfr-item-viewer .progress-container a {
  position: absolute;
  right: 30px;
}
.lfr-item-viewer .progress-container .progress {
  margin-top: 10px;
}
.lfr-item-viewer .search-info {
  background-color: #d3e8f1;
}
.lfr-item-viewer .search-info .keywords {
  font-size: 1.4em;
  font-weight: bold;
}
.lfr-item-viewer .search-info .change-search-folder {
  font-size: 0.8em;
  font-weight: normal;
}
.lfr-item-viewer .upload-view {
  display: table;
  height: 400px;
  margin-top: 20px;
  width: 100%;
}
.lfr-item-viewer .upload-view > div {
  display: table-cell;
  vertical-align: middle;
}
.lfr-item-viewer .yui3-widget-bd {
  position: relative;
}

.lfr-menu-expanded li a:focus {
  background-color: #5b677d;
  color: #fff;
  text-shadow: -1px -1px #2c2f34;
}

.lfr-url-error {
  display: inline-block;
  white-space: normal;
  overflow-wrap: break-all;
  word-wrap: break-all;
}

.lfr-page-layouts {
  padding: 0;
}
.lfr-page-layouts input[type=radio] {
  opacity: 0;
  position: absolute;
}
.lfr-page-layouts input[type=radio]:checked + .card-horizontal {
  cursor: default;
}
.lfr-page-layouts input[type=radio]:checked + .card-horizontal::after {
  bottom: -0.0625rem;
  content: "";
  left: -0.0625rem;
  position: absolute;
  right: -0.0625rem;
  transition: height 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .lfr-page-layouts input[type=radio]:checked + .card-horizontal::after {
    transition: none;
  }
}
.lfr-page-layouts input[type=radio]:checked + .card-horizontal::after {
  transition: none;
}

.lfr-page-layouts .card-horizontal {
  cursor: pointer;
  outline: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .lfr-page-layouts .card-horizontal {
    transition: none;
  }
}
.lfr-page-layouts .card-horizontal {
  transition: none;
}

.lfr-page-layouts .card-horizontal::after {
  border-radius: 0 0 0.25rem 0.25rem;
  bottom: -0.0625rem;
  content: "";
  height: 0;
  left: -0.0625rem;
  position: absolute;
  right: -0.0625rem;
  transition: height 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .lfr-page-layouts .card-horizontal::after {
    transition: none;
  }
}
.lfr-page-layouts .card-horizontal::after {
  transition: none;
}

.modal-body.dialog-iframe-bd {
  overflow: hidden;
  padding: 0;
}

.modal-dialog:not(.dialog-iframe-modal):not(.modal-full-screen) {
  position: relative;
}
.modal-dialog.dialog-iframe-modal {
  max-width: none;
}
.modal-dialog.modal-dialog-sm {
  max-width: 500px;
}
.modal-dialog .yui3-resize-handles-wrapper {
  pointer-events: all;
}
.modal-dialog .yui3-resize-handles-wrapper .yui3-resize-handle-inner-br {
  bottom: 0;
  right: 0;
}

.modal-open .modal {
  display: block;
}

.sheet > .panel-group .sheet-footer {
  margin-bottom: 0;
}

.sheet-footer .btn {
  margin-right: 0.5rem;
}
.sheet-footer .btn:last-child {
  margin-right: 0;
}

.tag-items {
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0 1em 0.5em;
  white-space: normal;
}
.tag-items li {
  display: inline-block;
  margin: 0 1em 0 0;
  max-width: 100%;
}

.tag-selected {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.tag-cloud .tag-popularity-1 {
  font-size: 1em;
}
.tag-cloud .tag-popularity-2 {
  font-size: 1.3em;
}
.tag-cloud .tag-popularity-3 {
  font-size: 1.6em;
}
.tag-cloud .tag-popularity-4 {
  font-size: 1.9em;
}
.tag-cloud .tag-popularity-5 {
  font-size: 2.2em;
}
.tag-cloud .tag-popularity-6 {
  font-size: 2.5em;
}

.lfr-portal-tooltip {
  display: inline-flex;
}
.lfr-portal-tooltip,
.lfr-portal-tooltip a {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.tree-node .icon-check {
  padding-right: 2px;
}
.tree-node .tree-node-checked .icon-check {
  padding-right: 0;
}
.tree-node [class^=icon-] {
  margin-right: 5px;
}
.tree-node .tree-node-checkbox-container {
  margin-right: 0;
}
.tree-node .tree-node-selected .tree-label {
  background: none;
}

.tree-node-content .tree-hitarea {
  color: #999;
  font-size: 10px;
  padding-right: 6px;
}
.tree-node-content .tree-label {
  margin-left: 3px;
}
.tree-node-content .tree-node-hidden-hitarea {
  visibility: hidden;
}

.tree-node-selected .tree-label {
  background-color: transparent;
}

.tree-view li.tree-node .tree-node-content svg.lexicon-icon {
  pointer-events: none;
}

.user-icon-color-0 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #6c757d;
}

.user-icon-color-1 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #66abff;
}

.user-icon-color-2 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #ff6200;
}

.user-icon-color-3 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #ff4d4d;
}

.user-icon-color-4 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #24a892;
}

.user-icon-color-5 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #ff4db2;
}

.user-icon-color-6 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #53a117;
}

.user-icon-color-7 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #bf66ff;
}

.user-icon-color-8 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #b38900;
}

.user-icon-color-9 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #dee2e6;
  color: #0099e6;
}

.collapse.open {
  display: block;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline%20bars-line-top'%20d='M480%2064H32C14.336%2064%200%2049.664%200%2032S14.336%200%2032%200h448c17.664%200%2032%2014.336%2032%2032s-14.336%2032-32%2032z'%20fill='%23272833'/%3E%3Cpath%20class='lexicon-icon-outline%20bars-line-middle'%20d='M480%20288H32c-17.664%200-32-14.336-32-32s14.336-32%2032-32h448c17.664%200%2032%2014.336%2032%2032s-14.336%2032-32%2032z'%20fill='%23272833'/%3E%3Cpath%20class='lexicon-icon-outline%20bars-line-bottom'%20d='M480%20512H32c-17.664%200-32-14.336-32-32s14.336-32%2032-32h448c17.664%200%2032%2014.336%2032%2032s-14.336%2032-32%2032z'%20fill='%23272833'/%3E%3C/svg%3E");
  height: 1em;
  width: 1em;
}

.navbar-nav .nav-item.hover:after {
  bottom: -0.125rem;
  content: "";
  height: 0.125rem;
  left: 0;
  position: absolute;
  width: 100%;
}

.navbar .navbar-toggler .c-inner {
  max-width: none;
}

html:not(#__):not(#___) .cadmin.portlet-topper {
  position: relative;
}
html:not(#__):not(#___) .cadmin.portlet-topper .portlet-topper-toolbar .portlet-icon-back {
  background: url(../images/arrows/12_left.png) no-repeat 0 50%;
  padding: 5px 5px 5px 18px;
}
html:not(#__):not(#___) .cadmin.portlet-topper .portlet-topper-toolbar .portlet-options .lfr-icon-menu-text {
  display: none;
}
html:not(#__):not(#___) .cadmin .component-action.portlet-options {
  color: white;
}

body.portlet {
  border-width: 0;
}

.portlet-icon-back {
  margin-top: -2px;
}
.portlet-topper .portlet-topper-toolbar .portlet-icon-back {
  background: url(../images/arrows/12_left.png) no-repeat 0 50%;
  padding: 5px 5px 5px 18px;
}

.portlet-options-dropdown {
  z-index: 1035;
}

.portlet-topper {
  position: relative;
}
.portlet-topper .portlet-topper-toolbar .portlet-options .lfr-icon-menu-text {
  display: none;
}
.portlet-draggable .portlet-topper {
  cursor: move;
}

.portlet-title-editable {
  cursor: pointer;
}

.portlet-title-text {
  display: inline-block;
  margin-top: 0;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}
.panel-page-body .portlet-title-text, .panel-page-content .portlet-title-text {
  cursor: auto;
}

.portlet-minimized .portlet-content {
  padding: 0;
}

.portlet-nested-portlets .portlet-boundary {
  left: 0 !important;
  position: relative !important;
  top: 0 !important;
}

.portlet-layout .portlet-header {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .portlet .visible-interaction {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .controls-hidden .portlet .visible-interaction {
    display: none;
  }
}
.portlet:hover .visible-interaction, .portlet.open .visible-interaction, .portlet.focus .visible-interaction {
  display: block;
}

.controls-hidden .lfr-meta-actions,
.controls-hidden .lfr-configurator-visibility {
  display: none;
}
.controls-hidden .portlet-title-editable {
  pointer-events: none;
}
.controls-hidden .portlet-topper {
  display: none !important;
}
.controls-hidden .portlet-topper-toolbar {
  display: none !important;
}

html:not(#__):not(#___) .cadmin.portlet-topper {
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 0 0;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  color: rgba(0, 0, 0, 0.5);
  display: box;
  display: flex;
  height: 28px;
  padding: 3px 12px 3px 24px;
  position: relative;
}
html:not(#__):not(#___) .cadmin.portlet-topper .lexicon-icon {
  height: 12px;
  width: 12px;
}
html:not(#__):not(#___) .cadmin.portlet-topper .lexicon-icon.lexicon-icon-drag {
  height: 16px;
  width: 16px;
}
html:not(#__):not(#___) .portlet > .cadmin.portlet-topper {
  display: none;
}
@media (min-width: 768px) {
  html:not(#__):not(#___) .portlet > .cadmin.portlet-topper {
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translateY(-97%);
    transition: opacity 0.15s;
    top: 0;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  html:not(#__):not(#___) .portlet > .cadmin.portlet-topper {
    transition: none;
  }
}
@media (min-width: 768px) {
  html:not(#__):not(#___) .portlet > .c-prefers-reduced-motion .cadmin.portlet-topper {
    transition: none;
  }
}
html:not(#__):not(#___) .cadmin .portlet-actions {
  float: right;
}
html:not(#__):not(#___) .cadmin .portlet-options {
  display: inline-block;
}
html:not(#__):not(#___) .cadmin .portlet-title-menu {
  flex: 0 1 auto;
}
html:not(#__):not(#___) .cadmin .portlet-title-menu > span > a {
  display: inline-block;
  text-decoration: none;
}
html:not(#__):not(#___) .cadmin .portlet-topper-toolbar {
  margin: 0;
  padding-left: 0;
}
html:not(#__):not(#___) .cadmin .portlet-topper-toolbar > a,
html:not(#__):not(#___) .cadmin .portlet-topper-toolbar > span > a,
html:not(#__):not(#___) .cadmin .portlet-topper-toolbar .lfr-icon-menu > a {
  color: rgba(0, 0, 0, 0.5);
}
html:not(#__):not(#___) .cadmin .portlet-topper-toolbar > a:focus, html:not(#__):not(#___) .cadmin .portlet-topper-toolbar > a:hover {
  text-decoration: none;
}
html:not(#__):not(#___) .cadmin .portlet-name-text {
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
}
html:not(#__):not(#___) .cadmin .portlet-title-default {
  flex: 1 1 auto;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 576px) {
  html:not(#__):not(#___) .lfr-configurator-visibility .portlet:hover .portlet-topper, html:not(#__):not(#___) .lfr-configurator-visibility .portlet.focus .portlet-topper, html:not(#__):not(#___) .lfr-configurator-visibility .portlet.open .portlet-topper {
    opacity: 0.5;
  }
}
@media (min-width: 768px) {
  html:not(#__):not(#___) .controls-visible .portlet:hover > .portlet-content-editable, html:not(#__):not(#___) .controls-visible .portlet.open > .portlet-content-editable, html:not(#__):not(#___) .controls-visible .portlet.focus > .portlet-content-editable {
    border-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 768px) {
  html:not(#__):not(#___) .controls-visible .portlet:hover > .cadmin.portlet-topper, html:not(#__):not(#___) .controls-visible .portlet.open > .cadmin.portlet-topper, html:not(#__):not(#___) .controls-visible .portlet.focus > .cadmin.portlet-topper {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  html:not(#__):not(#___) .controls-visible .portlet.open > .cadmin.portlet-topper {
    transition-duration: 0ms;
  }
}
@media (max-width: 767.98px) {
  html:not(#__):not(#___) .controls-visible .cadmin.portlet-topper {
    align-items: center;
    display: box;
    display: flex;
  }
}
html:not(#__):not(#___) .controls-visible .cadmin .portlet-topper-toolbar {
  display: block;
}

.portlet {
  margin-bottom: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .controls-visible .portlet:hover > .portlet-content-editable, .controls-visible .portlet.open > .portlet-content-editable, .controls-visible .portlet.focus > .portlet-content-editable {
    border-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 768px) {
  .controls-visible .portlet:hover > .portlet-topper, .controls-visible .portlet.open > .portlet-topper, .controls-visible .portlet.focus > .portlet-topper {
    opacity: 1;
  }
}
@media (min-width: 576px) {
  .lfr-configurator-visibility .portlet:hover .portlet-topper, .lfr-configurator-visibility .portlet.focus .portlet-topper, .lfr-configurator-visibility .portlet.open .portlet-topper {
    opacity: 0.5;
  }
}

.portlet-content-editable {
  border-color: transparent;
  border-radius: 0 0;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  transition: border-color 0.15s ease;
}
.portlet > .portlet-content-editable {
  border-color: transparent;
}
@media (max-width: 767.98px) {
  .controls-visible .portlet-content-editable {
    border-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.portlet-options {
  display: inline-block;
}

.portlet-title-default {
  flex: 1 1 auto;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.portlet-title-menu {
  flex: 0 1 auto;
}
.portlet-title-menu > span > a {
  display: inline-block;
  text-decoration: none;
}

.portlet-topper {
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 0 0;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  color: rgba(0, 0, 0, 0.5);
  display: box;
  display: flex;
  padding: 3px 12px 3px 24px;
  position: relative;
}
.portlet > .portlet-topper {
  display: none;
}
@media (min-width: 768px) {
  .portlet > .portlet-topper {
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity 0.15s;
    top: 0;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .portlet > .portlet-topper {
    transition: none;
  }
}
@media (min-width: 768px) {
  .portlet > .portlet-topper {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .controls-visible .portlet-topper {
    display: box;
    display: flex;
  }
}

.portlet-topper-toolbar {
  margin: 0;
  padding-left: 0;
}
.portlet-topper-toolbar > a,
.portlet-topper-toolbar > span > a,
.portlet-topper-toolbar .lfr-icon-menu > a {
  color: rgba(0, 0, 0, 0.5);
}
.portlet-topper-toolbar > a:focus, .portlet-topper-toolbar > a:hover {
  text-decoration: none;
}
.controls-visible .portlet-topper-toolbar {
  display: block;
}

.lfr-panel-page .portlet-title {
  font-size: 13px;
}

.portlet-borderless .portlet-content {
  padding: 1rem;
}
.portlet-decorate .portlet-content {
  background: #fff;
  border-color: transparent;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  padding: 1rem;
  word-wrap: break-word;
}
.portlet-barebone .portlet-content {
  padding: 0;
}

.portlet-dynamic-data-lists-display .lfr-ddm-field-group,
.portlet-dynamic-data-lists .lfr-ddm-field-group {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 28px 24px;
}

.breadcrumb.breadcrumb-vertical {
  display: inline-block;
  text-align: center;
}
.breadcrumb.breadcrumb-vertical li {
  display: block;
}
.breadcrumb.breadcrumb-vertical li.last, .breadcrumb.breadcrumb-vertical li.only {
  background: none;
}
.breadcrumb.breadcrumb-vertical .divider {
  background: url(../images/arrows/07_down.png) no-repeat 50% 100%;
  display: block;
  height: 10px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

.navbar form {
  margin: 0;
}

:not(.inline-item) > .loading-animation {
  margin-bottom: 20px;
  margin-top: 20px;
}
.product-menu .loading-animation {
  margin-top: 160px;
}

@keyframes lfr-drop-active {
  0% {
    background-color: #ebebeb;
    border-color: #ddd;
  }
  50% {
    background-color: #ddedde;
    border-color: #7d7;
    transform: scale(1.1);
  }
  75% {
    background-color: #ddedde;
    border-color: #7d7;
  }
  100% {
    background-color: #ebebeb;
    border-color: #ddd;
  }
}
.lfr-upload-container .progress-bar,
.lfr-upload-container .progress {
  border-radius: 10px;
}
.lfr-upload-container .upload-file,
.lfr-upload-container .upload-target {
  border-radius: 5px;
}
.upload-drop-active .lfr-upload-container .upload-target {
  animation: none;
}
.upload-drop-intent .lfr-upload-container .upload-target {
  animation: lfr-drop-active 1s ease 0.2s infinite;
}

.select-files {
  border-radius: 5px;
}

.taglib-form-navigator > .form-steps > ul.form-navigator.list-group {
  box-shadow: none;
}
.taglib-form-navigator > .form-steps > ul.form-navigator.list-group .tab .tab-label:hover .number, .taglib-form-navigator > .form-steps > ul.form-navigator.list-group .tab .tab-label:focus .number {
  box-shadow: 0 0 5px 0 #333;
  transition-duration: 0.25s;
  transition-property: box-shadow;
  transition-timing-function: ease-out;
}
.taglib-form-navigator > .form-steps > ul.form-navigator.list-group .tab .tab-label .number {
  border-radius: 50%;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.lfr-progress-active .progress-bar-status,
.lfr-upload-container .file-uploading .progress-bar .progress {
  animation: progress-bar-stripes 0.5s linear infinite;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0));
  background-size: 40px 40px;
  transition: width, 0.5s, ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .lfr-progress-active .progress-bar-status,
  .lfr-upload-container .file-uploading .progress-bar .progress {
    transition: none;
  }
}
.c-prefers-reduced-motion .lfr-progress-active .progress-bar-status,
.c-prefers-reduced-motion .lfr-upload-container .file-uploading .progress-bar .progress {
  transition: none;
}

@keyframes highlight-animation {
  from {
    background-color: #ffc;
  }
  to {
    background-color: transparent;
  }
}
.highlight-animation {
  animation: highlight-animation 0.7s;
}

.portlet-options.btn-group .dropdown-toggle, .portlet-options.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.form-group.form-inline.input-boolean-wrapper label, .form-group.form-inline.input-checkbox-wrapper label {
  gap: 0.3125rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-400-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-400-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter Medium";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-500-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-500-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter Bold";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-800-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/inter@latest/latin-800-normal.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-400-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-400-normal.woff) format("woff"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-400-normal.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
@font-face {
  font-family: "Tajawal Medium";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-500-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-500-normal.woff) format("woff"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-500-normal.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
@font-face {
  font-family: "Tajawal Bold";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-800-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-800-normal.woff) format("woff"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-800-normal.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
@font-face {
  font-family: "Tajawal Extrabold";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-900-normal.woff2) format("woff2"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-900-normal.woff) format("woff"), url(https://cdn.jsdelivr.net/fontsource/fonts/tajawal@latest/arabic-900-normal.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
.rtl .dc-arrow-link-icon {
  margin-right: 4px;
}

.dc-max-w-container {
  max-width: 1312px;
  margin: 0 auto;
}

.dc-max-w-2xm {
  max-width: 53.5rem;
}

.dc-max-w-2xm {
  max-width: 54rem;
}

.dc-dropdown-menu.dropdown-menu-select.dropdown-menu {
  border-top: none;
  border-color: var(--navy-500);
  border-radius: 0;
  padding: 0;
  margin-top: 0;
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .dropdown-subheader {
  display: none;
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled .dropdown-item {
  padding: var(--dc-spacer-12);
  color: var(--navy-500);
  background-color: var(--white);
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled .dropdown-item svg {
  display: none;
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled .dropdown-item.active {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled .dropdown-item.active:hover {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled .dropdown-item:hover {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-dropdown-menu.dropdown-menu-select.dropdown-menu .list-unstyled .list-unstyled li:not(:last-child) {
  border-bottom: 1px solid var(--navy-500);
}

.dc-accordion ol,
.dc-accordion ul,
.dc-rich-text ol,
.dc-rich-text ul {
  padding-left: var(--dc-spacer-32);
}
.dc-accordion ol,
.dc-rich-text ol {
  list-style: decimal;
}
.dc-accordion ul,
.dc-rich-text ul {
  list-style: disc;
}

li.dc-active {
  background-image: url("/o/dubai-chamber-theme/src/images/icons/tag-icon.svg");
}

.dc-secondary-nav-container {
  overflow: hidden;
  min-height: 600px;
  z-index: 1001;
}

.dc-secondary-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
}

.submenu-nav {
  padding: 0 0 0 var(--dc-spacer-32);
  width: 30%;
}

.dc-col .dc-col-inner {
  width: 50%;
  height: 100% !important;
  background-color: var(--gray-200);
  z-index: 9;
  left: 50%;
  position: absolute;
}
.dc-col .dc-col-inner:before {
  content: "";
  background-color: var(--gray-200);
  display: block;
  width: 100%;
  height: 500px;
  position: absolute;
  top: -95px;
  z-index: -1;
}
.dc-col .dc-col-inner:after {
  content: "";
  background-color: var(--gray-200);
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: -95px;
  z-index: -1;
}

.dc-accordions .dc-accordion {
  @apply dc-border-t dc-border-navy-500 dc-py-20 dc-px-8 lg:dc-py-24 lg:dc-px-24;
}
.dc-accordions .dc-accordion h5 {
  color: var(--navy-500);
}
.dc-accordions .dc-accordion:last-child {
  @apply dc-border-b dc-border-navy-500;
}
.dc-accordions .dc-accordion span, .dc-accordions .dc-accordion p, .dc-accordions .dc-accordion a, .dc-accordions .dc-accordion cite, .dc-accordions .dc-accordion em, .dc-accordions .dc-accordion u, .dc-accordions .dc-accordion strong, .dc-accordions .dc-accordion pre,
.dc-accordions .dc-accordion ol, .dc-accordions .dc-accordion ul, .dc-accordions .dc-accordion li,
.dc-accordions .dc-accordion h1, .dc-accordions .dc-accordion h2, .dc-accordions .dc-accordion h3, .dc-accordions .dc-accordion h4 {
  @apply dc-text-navy-500;
}
.dc-accordions .dc-accordion .dc-accordion-icon {
  transform: rotate(0);
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  transition: transform 0.3s ease-out;
}
.dc-accordions .dc-accordion button {
  background-color: transparent;
}
.dc-accordions .dc-accordion.dc-accordion-open {
  background-color: var(--gray-50);
}
.dc-accordions .dc-accordion.dc-accordion-open h5 {
  color: var(--navy-500);
}
.dc-accordions .dc-accordion.dc-accordion-open .dc-accordion-icon {
  transform: rotate(45deg);
}
.dc-accordions .dc-accordion.dc-accordion-open button {
  background-color: transparent;
}

.portlet-type-facet,
.portlet-category-facet,
.portlet-modified-facet {
  margin-bottom: var(--dc-spacer-48);
}
.portlet-type-facet .collapse,
.portlet-category-facet .collapse,
.portlet-modified-facet .collapse {
  visibility: visible;
}
.portlet-type-facet .panel-header,
.portlet-category-facet .panel-header,
.portlet-modified-facet .panel-header {
  padding: 0.75rem 0 0 0;
}
.portlet-type-facet .panel-body,
.portlet-category-facet .panel-body,
.portlet-modified-facet .panel-body {
  padding: 0;
}
.portlet-type-facet .panel-title,
.portlet-category-facet .panel-title,
.portlet-modified-facet .panel-title {
  color: var(--navy-500);
  font-weight: 600;
}
.portlet-type-facet .collapse-icon-closed,
.portlet-type-facet .collapse-icon-open,
.portlet-category-facet .collapse-icon-closed,
.portlet-category-facet .collapse-icon-open,
.portlet-modified-facet .collapse-icon-closed,
.portlet-modified-facet .collapse-icon-open {
  font-size: 16px;
}
.portlet-type-facet .collapse-icon-closed svg,
.portlet-type-facet .collapse-icon-open svg,
.portlet-category-facet .collapse-icon-closed svg,
.portlet-category-facet .collapse-icon-open svg,
.portlet-modified-facet .collapse-icon-closed svg,
.portlet-modified-facet .collapse-icon-open svg {
  fill: var(--navy-500);
}
.portlet-type-facet .facet-value button:hover, .portlet-type-facet .facet-value button:focus, .portlet-type-facet .facet-value button:active,
.portlet-category-facet .facet-value button:hover,
.portlet-category-facet .facet-value button:focus,
.portlet-category-facet .facet-value button:active,
.portlet-modified-facet .facet-value button:hover,
.portlet-modified-facet .facet-value button:focus,
.portlet-modified-facet .facet-value button:active {
  outline: none;
  box-shadow: none;
}
.portlet-type-facet .facet-value small,
.portlet-category-facet .facet-value small,
.portlet-modified-facet .facet-value small {
  font-size: 100%;
}
.portlet-type-facet .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.portlet-category-facet .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.portlet-modified-facet .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("/o/dubai-chamber-theme/images/icons/checkbox.svg");
  background-size: 10px;
  background-position: center;
}
.portlet-type-facet .custom-control-label:before,
.portlet-category-facet .custom-control-label:before,
.portlet-modified-facet .custom-control-label:before {
  border-color: var(--navy-500);
  background-color: var(--gray-50);
}
.portlet-type-facet .btn-link:focus,
.portlet-category-facet .btn-link:focus,
.portlet-modified-facet .btn-link:focus {
  box-shadow: none;
}

#dc-header,
.has-edit-mode-menu #dc-header {
  margin-top: 0;
}
@media all and (min-width: 576px) {
  #dc-header,
  .has-edit-mode-menu #dc-header {
    margin-top: 0;
  }
}

.has-control-menu #dc-header {
  margin-top: 48px;
}
@media all and (min-width: 576px) {
  .has-control-menu #dc-header {
    margin-top: 56px;
  }
}

.dc-transform-bar {
  transform: translate(-100%);
}

.dc-top-header {
  @apply dc-flex dc-px-20 dc-relative 2xl:dc-static;
}
.dc-top-header__navbar-wrapper {
  @apply dc-flex dc-flex-wrap dc-items-center dc-justify-between;
  margin-right: auto;
  margin-left: 0;
}
.dc-top-header__menu-mobile {
  @apply dc-block xl:dc-hidden dc-py-12;
}
.dc-top-header__menu-head a {
  @apply focus:dc-no-underline hover:dc-no-underline;
}
.dc-top-header__navbar {
  @apply dc-w-full dc-h-full;
}
.dc-top-header__nav {
  @apply dc-font-medium dc-h-full md:dc-p-0 dc-mt-4 md:dc-flex-row /*rtl:dc-space-x-reverse*/ md:dc-mt-0 md:dc-border-0 dark:dc-bg-gray-800 md:dark:dc-bg-gray-900 dark:dc-border-gray-700;
}
.dc-top-header__nav__item {
  @apply dc-h-full dc-px-12 dc-flex dc-items-center;
}
.dc-top-header__nav__item a {
  @apply dc-text-white hover:dc-text-white hover:dc-no-underline;
}
.dc-top-header__nav__item.active {
  @apply dc-bg-gray-200 dc-relative;
}
.dc-top-header__nav__item.active a {
  @apply dc-text-white;
}
.dc-top-header__nav__item.active::after {
  @apply dc-absolute dc--bottom-4 dc-left-0 dc-right-0 dc-mx-auto dc-w-0 dc-h-0;
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 15px solid white;
}
.dc-top-header .dc-language-portlet a {
  @apply dc-text-navy-500 hover:dc-no-underline;
}
.dc-top-header__menu-body {
  @apply dc-flex-col dc-gap-16 dc-px-20 dc-pb-20 dc-top-full dc-w-full dc-left-0 dc-absolute;
  background-color: var(--brand-bg);
  z-index: 1001;
}
.dc-top-header__menu-body a {
  @apply dc-text-white dc-block;
}
.dc-top-header__link {
  @apply dc-text-white dc-block dc-py-12 hover:dc-text-white focus:dc-underline;
}
.dc-top-header button {
  @apply dc-flex dc-items-center;
}
.dc-top-header .dc-user-profile a {
  color: var(--navy-500);
}
@media all and (min-width: 769px) {
  .dc-top-header .dc-user-profile a {
    color: var(--white);
  }
  .dc-top-header .dc-user-profile a:hover {
    text-decoration: none;
  }
}

header {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.dc-bottom-header {
  @apply dc-bg-white dc-border-gray-200;
}
.dc-bottom-header__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.dc-bottom-header__nav-mobile .dc-h5 {
  color: var(--navy-500);
  padding: var(--dc-spacer-36) var(--dc-spacer-20);
  text-transform: capitalize;
}
.dc-bottom-header__nav-mobile .dc-nav-arrow {
  margin-top: -8px;
}
.dc-bottom-header__nav-mobile .dc-primary-nav__a {
  display: flex;
  align-items: center;
  color: var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__a:hover {
  color: var(--navy-500);
  text-decoration: none;
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item {
  width: 100%;
  border-bottom: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item:first-child {
  border-bottom: none;
  border-top: 1px solid var(--navy-500);
  border-bottom: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item:first-child .dc-secondary-nav__container {
  margin-bottom: -1px;
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item:last-child {
  border-bottom: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item.open > div {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item.open > div .dc-nav-arrow.up {
  background-color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item .dc-nav_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--dc-spacer-12) var(--dc-spacer-20) var(--dc-spacer-12) var(--dc-spacer-20);
  color: var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item .dc-nav_item.dc-open {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item .dc-nav_item.dc-nav-item--white.opened {
  background-color: var(--navy-500);
  color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-primary-nav__item .dc-nav_item.dc-nav-item--white.opened .dc-nav-arrow.up {
  background-color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container {
  background-color: var(--gray-200);
  border-top: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container.dc-hidden {
  height: 0;
  visibility: hidden;
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .dc-nav_item {
  padding-left: var(--dc-spacer-40);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .dc-secondary-nav {
  width: 100%;
  background-color: var(--white);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .dc-secondary-nav.dc-hidden {
  height: 0;
  visibility: hidden;
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .dc-secondary-nav .dc-nav_item {
  padding-left: var(--dc-spacer-56);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .level-2 li {
  border-bottom: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .level-2 .dc-secondary-nav .level-2 {
  border-bottom: 1px solid var(--navy-500);
}
.dc-bottom-header__nav-mobile .dc-secondary-nav__container .level-2 ul li {
  border-bottom: none;
}

.dc-nav-arrow {
  aspect-ratio: 1;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  width: 14px;
  transform: rotate(-135deg);
  background: var(--navy-500);
  margin-top: 2px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  transition: transform 0.3s ease-out;
}
.dc-nav-arrow.up {
  transform: rotate(45deg);
  margin-top: 8px;
}
.dc-nav-arrow--white {
  background: var(--white);
}
.dc-nav-arrow--down {
  transform: translate(-45deg);
}
.dc-nav-arrow--up {
  transform: rotate(45deg);
}
.dc-nav-arrow--left {
  transform: rotate(-45deg);
}
.dc-nav-arrow--right {
  transform: rotate(-225deg);
}

.dc-logo {
  max-width: 130px;
  margin-right: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
@media all and (min-width: 1025px) {
  .dc-logo {
    max-width: 160px;
    margin-right: var(--dc-spacer-32);
    position: static;
    transform: unset;
    padding: var(--dc-spacer-16) 0;
  }
}

li.dc-active {
  background-image: url("/o/dubai-chamber-theme/src/images/icons/tag-icon.svg");
}

.dc-mega-menu h4 {
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 var(--dc-spacer-24) var(--dc-spacer-12) var(--dc-spacer-24);
}
.dc-mega-menu__inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 1136px;
  margin: 0 auto;
  padding-inline: var(--dc-spacer-24);
}
.dc-mega-menu__left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid var(--navy-500);
  border-bottom: 1px solid var(--navy-500);
}
.dc-mega-menu__left .dc-nav-item {
  width: 100%;
  border-bottom: 1px solid var(--navy-500);
}
.dc-mega-menu__left .dc-nav-item:last-child {
  border-bottom: 0;
}
.dc-mega-menu__left .dc-nav-item.dc-open {
  background-color: var(--navy-500);
}
.dc-mega-menu__left .dc-nav-item.dc-open a {
  color: var(--white);
}
.dc-mega-menu__left .dc-nav-item + ul .dc-nav-item {
  padding-left: var(--dc-spacer-36);
  padding-right: var(--dc-spacer-36);
  width: 100%;
}
.dc-mega-menu__left .dc-nav-item + ul .dc-nav-item a {
  color: var(--navy-500);
}
.dc-mega-menu .dc-col__inner {
  background-color: var(--gray-200);
  width: 59.573%;
  height: 100% !important;
  z-index: 9;
  left: 40.427%;
  position: absolute;
}
.dc-mega-menu .dc-col__inner .dc-layer-top-back {
  content: "";
  background-color: var(--gray-200);
  display: block;
  width: 100%;
  height: 560px;
  position: absolute;
  top: -14%;
  z-index: -1;
}
.dc-mega-menu .dc-col__inner .dc-layer-bottom-back {
  content: "";
  background-color: var(--gray-200);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.dc-mega-menu .article-menu {
  padding: var(--dc-spacer-20);
  width: 100%;
}
@media all and (min-width: 769px) {
  .dc-mega-menu {
    overflow: auto;
    height: calc(90vh - 184px);
    z-index: 1001;
  }
  .dc-mega-menu__inner {
    flex-direction: row;
  }
  .dc-mega-menu__left {
    width: 70%;
    position: relative;
    background-color: transparent;
    border-color: transparent;
  }
  .dc-mega-menu .dc-nav-item {
    width: 40.427%;
    border-color: transparent;
    padding-right: var(--dc-spacer-16);
  }
  .dc-mega-menu .dc-nav-item a {
    width: 100%;
    padding: var(--dc-spacer-12) var(--dc-spacer-32) var(--dc-spacer-12) 0;
    color: var(--navy-500);
  }
  .dc-mega-menu .dc-nav-item:hover a {
    text-decoration: underline;
  }
  .dc-mega-menu .dc-nav-item.dc-parent-item-hovered {
    background-color: var(--gray-200);
    position: relative;
  }
  .dc-mega-menu .dc-nav-item.dc-parent-item-hovered:before {
    content: "";
    right: 0;
    height: calc(100% + 2px);
    background-color: var(--gray-200);
    width: 5000px;
    position: absolute;
    z-index: -1;
  }
  .dc-mega-menu .dc-nav-item.dc-open {
    background-color: transparent;
  }
  .dc-mega-menu .dc-nav-item.dc-open a {
    color: var(--navy-500);
  }
  .dc-mega-menu .dc-nav-item + ul .dc-nav-item {
    padding-left: var(--dc-spacer-32);
    padding-right: var(--dc-spacer-16);
    width: 100%;
  }
  .dc-mega-menu .article-menu {
    padding: 0 0 0 var(--dc-spacer-32);
    max-width: 336px;
  }
}

.rtl .dc-bottom-header .dc-h4 {
  padding-left: var(--dc-spacer-20);
  padding-right: var(--dc-spacer-32);
}
.rtl .dc-bottom-header .dc-nav-arrow--left {
  transform: rotate(-225deg);
}
.rtl .dc-bottom-header .dc-nav-arrow--right {
  transform: rotate(-45deg);
}
.rtl .dc-bottom-header .dc-col__inner {
  right: 50%;
}

.dc-collapsed-menu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 988;
}
.dc-collapsed-menu__inner {
  height: 0;
}
.dc-collapsed-menu__inner.dc-opened {
  height: 100vh;
}
.dc-collapsed-menu__bottom-section {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.dc-collapsed-menu .level-1 {
  overflow-y: auto;
  height: auto;
}
@media all and (min-width: 1025px) {
  .dc-collapsed-menu {
    position: static;
  }
  .dc-collapsed-menu__inner {
    display: unset;
    height: auto;
  }
  .dc-collapsed-menu__inner.dc-opened {
    height: auto;
  }
  .dc-collapsed-menu .level-1 {
    overflow-y: unset;
    height: unset;
  }
}

.dc-menu-btn.dc-opened .dc-closed-icon {
  @apply dc-hidden;
}
.dc-menu-btn.dc-opened .dc-opened-icon {
  @apply dc-block;
}
.dc-menu-btn .dc-closed-icon {
  @apply dc-block;
}
.dc-menu-btn .dc-opened-icon {
  @apply dc-hidden;
}

@media (min-width: 768px) {
  .dc-collection-col-3 .col.col-md-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.service-cards-wrapper .category-container__inner {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 769px) {
  .service-cards-wrapper .category-container__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
.service-cards-wrapper .journal-content-article {
  width: 100%;
  height: 100%;
}
.service-cards-wrapper .categories-list li.active button {
  border-bottom: 1px solid white;
}
.service-cards-wrapper .category-dropdown {
  color: #fff;
  background: #003764;
  border: 1px solid white;
  width: 100%;
}
.service-cards-wrapper .category-dropdown:focus {
  outline: none;
  --tw-ring-color: transparent;
}
.service-cards-wrapper .icon {
  position: absolute;
  right: 7px;
  top: 8px;
}

.rtl .category-dropdown {
  padding-right: 0.75rem !important;
}

.breadcrumb {
  background-color: var(--white);
  border-radius: 0;
  padding: 32px 20px 24px;
  margin: 0;
}
@media (max-width: 768px) {
  .breadcrumb {
    padding: 24px 16px;
  }
}
.breadcrumb .breadcrumb-link,
.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item.active, .breadcrumb .breadcrumb-item .active,
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: var(--navy-500);
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  margin-top: 0;
  top: 10%;
  left: -11px;
  background-image: url("../images/icons/chevron-right.svg");
  height: 24px;
  width: 24px;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 16px;
}
.breadcrumb .breadcrumb-item {
  margin-right: 16px;
}

.asw-container .asw-menu-header {
  background-color: var(--navy-500) !important;
}
.asw-container .asw-menu-header svg {
  fill: var(--navy-500) !important;
}
.asw-container .asw-menu-content {
  max-height: calc(100% - 38px);
}
.asw-container .asw-footer {
  display: none;
}
.asw-container .asw-minus:hover, .asw-container .asw-plus:hover {
  border-color: var(--navy-500) !important;
}
.asw-container .asw-btn.asw-selected, .asw-container .asw-btn:hover {
  border-color: var(--navy-500) !important;
}
.asw-container .asw-adjust-font div[role=button] {
  cursor: inherit;
}
.asw-container .asw-menu * {
  line-height: initial !important;
}

.dc-treeview-link span {
  color: var(--navy-500);
}
.dc-treeview-link.active {
  background-color: transparent;
  text-decoration: underline;
}
.dc-treeview-link.active span {
  color: var(--navy-500);
}
.dc-treeview-link:hover, .dc-treeview-link:active, .dc-treeview-link:focus {
  background-color: transparent;
}
.dc-treeview-link:hover span, .dc-treeview-link:active span, .dc-treeview-link:focus span {
  color: var(--navy-500);
}

.dc-btn {
  width: auto !important;
}

.dc-max-w-container-search {
  max-width: 1312px;
  margin: 0 auto;
}
@media (max-width: 1312px) {
  .dc-max-w-container-search {
    padding: 0 16px;
  }
}

.dc-search-categories .treeview-link {
  padding-left: 12px;
  margin: 16px 0 0;
}
.dc-search-categories .treeview-link .custom-control {
  margin: 0;
}
.dc-search-categories .treeview-link .custom-control .custom-control-label-text {
  color: var(--navy-500);
}
.dc-search-categories .treeview-link .custom-checkbox .custom-control-input ~ .custom-control-label::before {
  border-radius: 0;
}
.dc-search-categories .treeview-link .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--navy-500);
  background-color: var(--navy-500);
}
.dc-search-categories .treeview-link .custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--navy-500);
  background-color: transparent;
}

.dc-modified-facet .custom-checkbox .custom-control-input ~ .custom-control-label::before {
  border-radius: 0;
}
.dc-modified-facet .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--navy-500);
  background-color: var(--navy-500);
}
.dc-modified-facet .custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--navy-500);
  background-color: transparent;
}
.dc-modified-facet .facet-value {
  margin: 16px 0 0;
}
.dc-modified-facet .custom-control-label-text {
  color: var(--navy-500);
}
.dc-modified-facet .field-wrapper.mb-3 {
  margin-bottom: 0;
}
.dc-modified-facet .field-wrapper.mb-3 .control-label {
  color: var(--navy-500);
  margin-bottom: 4px;
  display: block;
}
.dc-modified-facet .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.dc-modified-facet .lfr-input-date input {
  font-family: "Inter", "Tajawal";
  font-size: var(--s-font-size-mobile);
  line-height: var(--s-line-height-mobile);
  letter-spacing: var(--s-letter-spacing-mobile);
  color: var(--black);
}
@media all and (min-width: 769px) {
  .dc-modified-facet .lfr-input-date input {
    font-family: "Inter";
    font-size: var(--s-font-size);
    line-height: var(--s-line-height);
    letter-spacing: var(--s-letter-spacing);
  }
}
.dc-modified-facet .lfr-input-date input::placeholder {
  font-family: "Inter", "Tajawal";
  font-size: var(--s-font-size-mobile);
  line-height: var(--s-line-height-mobile);
  letter-spacing: var(--s-letter-spacing-mobile);
  color: #000;
}
@media all and (min-width: 769px) {
  .dc-modified-facet .lfr-input-date input::placeholder {
    font-family: "Inter";
    font-size: var(--s-font-size);
    line-height: var(--s-line-height);
    letter-spacing: var(--s-letter-spacing);
  }
}
.dc-modified-facet .lfr-input-date input:focus {
  box-shadow: none;
}
.dc-modified-facet .has-success .form-control {
  border-color: var(--navy-500);
}

.datepicker-popover {
  border-radius: 0;
  border-color: var(--navy-500);
}
.datepicker-popover .yui3-calendar-day.yui3-calendar-day-selected,
.datepicker-popover .yui3-calendar-day:hover {
  background-color: var(--navy-500);
  color: var(--white);
}
.datepicker-popover .yui3-calendarnav-prevmonth {
  border-right-color: var(--navy-500);
}
.datepicker-popover .yui3-calendarnav-prevmonth:hover {
  border-right-color: var(--navy-500);
}
.datepicker-popover .yui3-calendarnav-nextmonth {
  border-left-color: var(--navy-500);
}
.datepicker-popover .yui3-calendarnav-nextmonth:hover {
  border-left-color: var(--navy-500);
}

#search-results-display-list .card-title .mark,
#search-results-display-list .subtext-item .mark {
  background-color: transparent;
  box-shadow: none;
}

.dc-common-new-banner {
  height: 680px;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
}
.dc-common-new-banner h5 {
  color: #fff;
  font-size: 48px !important;
  font-weight: 700;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .dc-common-new-banner h5 {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}
.dc-common-new-banner .dc-text-m {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
@media (max-width: 767px) {
  .dc-common-new-banner .dc-text-m {
    font-size: 16px;
    line-height: 26px;
  }
}
.dc-common-new-banner .dc-max-w-container {
  z-index: 9999;
  width: 100%;
}
@media (max-width: 767px) {
  .dc-common-new-banner .dc-max-w-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.dc-common-new-banner:before {
  content: "";
  background: rgba(31, 42, 55, 0.2);
  position: absolute;
  height: 100%;
  width: 100%;
}
.dc-common-new-banner:after {
  content: "";
  background: linear-gradient(to bottom, transparent, transparent 40%, black 100%);
  height: 300px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

body.has-edit-mode-menu .dc-common-new-banner:after,
body.has-edit-mode-menu .dc-common-new-banner:before {
  display: none;
}

.venue-banner .dc-btn > img {
  display: none;
}

.journal-content-article__inner {
  @apply dc-mb-32;
}
.journal-content-article__image {
  width: 304px;
  height: 172px;
  object-fit: cover;
}
.journal-content-article__text {
  @apply dc-underline dc-text-s dc-text-navy-500 dc-pt-8;
  max-width: 304px;
}

body {
  font-family: "Inter", sans-serif;
  /* DC-no-image-card,
    DC-highlight-element,
    Services card portlet */
  /*------------ Modal -----------*/
  /*------------ Liferay form -----------*/
  /*.dc-bottom-corner-sm {
    clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0,  100% calc(100% - 14px),calc(100% - 14px) 100%, 0 100%,  0 100%);
  }

  .dc-bottom-corner-md {
    clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0,  100% calc(100% - 32px),calc(100% - 32px) 100%, 0 100%,  0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0,  100% calc(100% - 14px),calc(100% - 14px) 100%, 0 100%,  0 100%);
    }
  }

  .dc-bottom-corner-xl {
    clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0,  100% calc(100% - 64px),calc(100% - 64px) 100%, 0 100%,  0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0,  100% calc(100% - 14px),calc(100% - 14px) 100%, 0 100%,  0 100%);
    }
  }

  .dc-top-corner-sm {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  0 100%, 0 100%);
  }

  .dc-top-corner-md {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 32px) 0%, 100% 32px, 100% 100%, 100% 100%,  0 100%, 0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  0 100%, 0 100%);
    }
  }

  .dc-diagonal-corners-sm {
    clip-path: polygon(0% 14px, 14px 0%, calc(100% - 14px) 0%, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%,  0 100%, 0 100%);
  }

  .dc-diagonal-corners-md {
    clip-path: polygon(0% 32px, 32px 0%, calc(100% - 32px) 0%, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%,  0 100%, 0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 14px, 14px 0%, calc(100% - 14px) 0%, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%,  0 100%, 0 100%);
    }
  }

  .dc-diagonal-corners-xl {
    clip-path: polygon(0% 64px, 64px 0%, calc(100% - 64px) 0%, 100% 0, 100% calc(100% - 64px), calc(100% - 64px) 100%,  0 100%, 0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 32px, 32px 0%, calc(100% - 32px) 0%, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%,  0 100%, 0 100%);
    }
  }

  .card-image {
    overflow: hidden;
    position: relative;
  }*/
  /*.cursor-hover {
    all:unset;
    display: none;
    position: fixed;
    z-index: 1002;
    left: 0;
    top: 0;
    width: 127px;
    height: 127px;
    background: rgba(255, 255, 255, 0.01);
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 50% 65%, 65% 50%, 50% 33%);
    backdrop-filter: blur(4px);

    img {
      position: absolute;
      top: 0;
      left: 58px;
    }
  }*/
  /*&.no-cursor {
    a:hover,
    a:hover img {
      cursor: none !important;
    }
    cursor: none !important;
  }*/
}
body .dc-rich-text h1 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h1-font-size-mobile);
  line-height: var(--h1-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-rich-text h1 {
    font-family: "Inter Bold";
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
  }
}
body .dc-rich-text h2 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h2-font-size-mobile);
  line-height: var(--h2-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-rich-text h2 {
    font-family: "Inter Bold";
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
  }
}
body .dc-rich-text h3 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h3-font-size-mobile);
  line-height: var(--h3-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-rich-text h3 {
    font-family: "Inter Bold";
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
  }
}
body .dc-rich-text h4 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h4-font-size-mobile);
  line-height: var(--h4-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-rich-text h4 {
    font-family: "Inter Bold";
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
  }
}
body .dc-h1 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h1-font-size-mobile);
  line-height: var(--h1-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h1 {
    font-family: "Inter Bold";
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
  }
}
body .dc-h2,
body .dc-accordion h1 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h2-font-size-mobile);
  line-height: var(--h2-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h2,
  body .dc-accordion h1 {
    font-family: "Inter Bold";
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
  }
}
body .dc-h3,
body .dc-accordion h2 {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h3-font-size-mobile);
  line-height: var(--h3-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h3,
  body .dc-accordion h2 {
    font-family: "Inter Bold";
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
  }
}
body .dc-h4,
body .dc-accordion h3,
body .portlet-forms .lfr-ddm-form-page-title {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h4-font-size-mobile);
  line-height: var(--h4-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h4,
  body .dc-accordion h3,
  body .portlet-forms .lfr-ddm-form-page-title {
    font-family: "Inter Bold";
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
  }
}
body .dc-h5,
body .dc-accordion h4,
body .portlet-forms .ddm-field-types-fieldset__nested > label:first-child {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h5-font-size-mobile);
  line-height: var(--h5-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h5,
  body .dc-accordion h4,
  body .portlet-forms .ddm-field-types-fieldset__nested > label:first-child {
    font-family: "Inter Bold";
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}
body .dc-h6,
body .portlet-forms .ddm-label,
body .portlet-forms .lfr-ddm-legend,
body .dc-group-label,
body .portlet-forms .form-group.has-error .form-feedback-item,
body .portlet-forms .form-group.has-error .dc-form-feedback-item,
body .dc-terms-and-conditions-error,
body .dc-treeview-link.active span {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h6-font-size-mobile);
  line-height: var(--h6-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-h6,
  body .portlet-forms .ddm-label,
  body .portlet-forms .lfr-ddm-legend,
  body .dc-group-label,
  body .portlet-forms .form-group.has-error .form-feedback-item,
  body .portlet-forms .form-group.has-error .dc-form-feedback-item,
  body .dc-terms-and-conditions-error,
  body .dc-treeview-link.active span {
    font-family: "Inter Bold";
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
  }
}
body .dc-h7 {
  font-family: "Inter Medium", "Tajawal Medium";
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: -0.28px;
}
body .dc-text-xs {
  font-family: "Inter Medium", "Tajawal Medium";
  font-size: var(--xs-font-size-mobile);
  line-height: var(--xs-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-text-xs {
    font-family: "Inter Medium";
    font-size: var(--xs-font-size);
    line-height: var(--xs-line-height);
  }
}
body .dc-text-s,
body .portlet-forms label .custom-control-label,
body .portlet-forms .form-group .form-feedback-group,
body .dc-treeview-link span {
  font-family: "Inter", "Tajawal";
  font-size: var(--s-font-size-mobile);
  line-height: var(--s-line-height-mobile);
  letter-spacing: var(--s-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .dc-text-s,
  body .portlet-forms label .custom-control-label,
  body .portlet-forms .form-group .form-feedback-group,
  body .dc-treeview-link span {
    font-family: "Inter";
    font-size: var(--s-font-size);
    line-height: var(--s-line-height);
    letter-spacing: var(--s-letter-spacing);
  }
}
body .dc-text-m,
body .dc-accordion p,
body .dc-accordion a,
body .dc-accordion span,
body .dc-accordion li,
body .dc-accordion cite,
body .dc-accordion em,
body .dc-accordion u,
body .dc-accordion strong,
body .dc-accordion pre,
body .dc-rich-text p,
body .dc-rich-text a,
body .dc-rich-text span,
body .dc-rich-text li,
body .dc-rich-text cite,
body .dc-rich-text em,
body .dc-rich-text u,
body .dc-rich-text strong,
body .dc-rich-text pre {
  font-family: "Inter", "Tajawal";
  font-size: var(--m-font-size-mobile);
  line-height: var(--m-line-height-mobile);
  letter-spacing: var(--m-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .dc-text-m,
  body .dc-accordion p,
  body .dc-accordion a,
  body .dc-accordion span,
  body .dc-accordion li,
  body .dc-accordion cite,
  body .dc-accordion em,
  body .dc-accordion u,
  body .dc-accordion strong,
  body .dc-accordion pre,
  body .dc-rich-text p,
  body .dc-rich-text a,
  body .dc-rich-text span,
  body .dc-rich-text li,
  body .dc-rich-text cite,
  body .dc-rich-text em,
  body .dc-rich-text u,
  body .dc-rich-text strong,
  body .dc-rich-text pre {
    font-family: "Inter";
    font-size: var(--m-font-size);
    line-height: var(--m-line-height);
    letter-spacing: var(--m-letter-spacing);
  }
}
body .dc-text-l {
  font-family: "Inter", "Tajawal";
  font-size: var(--l-font-size-mobile);
  line-height: var(--l-line-height-mobile);
  letter-spacing: var(--l-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .dc-text-l {
    font-family: "Inter";
    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
    letter-spacing: var(--l-letter-spacing);
  }
}
body .dc-text-xl {
  font-family: "Inter", "Tajawal";
  font-size: var(--xl-font-size-mobile);
  line-height: var(--xl-line-height-mobile);
  letter-spacing: var(--xl-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .dc-text-xl {
    font-family: "Inter";
    font-size: var(--xl-font-size);
    line-height: var(--xl-line-height);
    letter-spacing: var(--xl-letter-spacing);
  }
}
body .dc-accordion a {
  text-decoration: underline;
}
body .dc-btn {
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h5-font-size-mobile);
  line-height: var(--h5-line-height-mobile);
  padding: var(--dc-spacer-8) var(--dc-spacer-20);
  position: relative;
  width: 100%;
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%,
    0 100%
  );*/
  transition: 0.5s ease-in-out;
  min-height: 41px;
}
@media all and (min-width: 769px) {
  body .dc-btn {
    font-family: "Inter Bold";
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}
body .dc-btn .bg-btn {
  transition: 0.5s ease-in-out;
}
body .dc-btn .btn-span {
  position: relative;
}
body .dc-btn .btn-span,
body .dc-btn .btn-span a {
  display: block;
  transition: 0.5s ease-in-out;
}
body .dc-btn .btn-span a:hover,
body .dc-btn .btn-span a a:hover {
  color: inherit;
  text-decoration: none;
}
body .dc-btn.js-hide-modal-btn {
  width: auto;
}
body .dc-btn--icon .btn-span {
  padding-left: var(--dc-spacer-28);
  text-align: left;
}
body .dc-btn--icon .dc-btn--icon-el {
  transition: 0.5s ease-in-out;
  position: absolute;
  z-index: 9;
}
body .dc-btn--blue {
  background: var(--navy-500);
}
body .dc-btn--blue .btn-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  background-color: var(--navy-500);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--blue .btn-span,
body .dc-btn--blue .btn-span a {
  color: var(--white);
}
body .dc-btn--blue .dc-btn--icon-el {
  background-color: var(--white);
}
body .dc-btn--blue img {
  display: none;
}
body .dc-btn--blue:hover {
  background-color: var(--white);
}
body .dc-btn--blue:hover .btn-bg {
  background: var(--white);
}
body .dc-btn--blue:hover .btn-span,
body .dc-btn--blue:hover .btn-span a {
  color: var(--navy-500);
}
body .dc-btn--blue:hover .dc-btn--icon-el {
  background-color: var(--navy-500);
}
body .dc-btn--white {
  background: var(--white);
}
body .dc-btn--white .btn-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  background-color: var(--white);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--white .btn-span,
body .dc-btn--white .btn-span a {
  color: var(--navy-500);
}
body .dc-btn--white:hover {
  background: var(--navy-500);
}
body .dc-btn--white:hover .btn-span,
body .dc-btn--white:hover .btn-span a {
  color: var(--white);
}
body .dc-btn--white:hover .btn-bg {
  background: var(--navy-500);
}
body .dc-btn--white:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(141deg) brightness(102%) contrast(101%);
}
body .dc-btn--white-outlined {
  background: var(--navy-500);
}
body .dc-btn--white-outlined .btn-bg {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: 0.5s ease;
  background-color: var(--white);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 11.5px),
    calc(100% - 11.5px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--white-outlined .btn-span {
  color: var(--navy-500);
}
body .dc-btn--white-outlined.dc-gallery-tab.dc-btn--small {
  min-width: 171px;
  width: auto;
}
body .dc-btn--white-outlined.dc-gallery-tab-active .btn-bg {
  background: var(--navy-500);
}
body .dc-btn--white-outlined.dc-gallery-tab-active .btn-span {
  color: var(--white);
}
body .dc-btn--white-outlined:hover .btn-span,
body .dc-btn--white-outlined:hover .btn-span a {
  color: var(--navy-500);
  text-decoration: underline;
}
body .dc-btn--white-outlined:hover .btn-bg {
  background: var(--white);
}
body .dc-btn--white-outlined:hover.dc-gallery-tab-active .btn-span {
  text-decoration: none;
  color: var(--white);
}
body .dc-btn--white-outlined:hover.dc-gallery-tab-active:hover .btn-span {
  color: var(--white);
  text-decoration: underline;
}
body .dc-btn--white-outlined:hover.dc-gallery-tab-active:hover .btn-bg {
  background-color: var(--navy-500);
}
body .dc-btn--blue-outlined {
  background: var(--white);
}
body .dc-btn--blue-outlined .btn-bg {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: 0.5s ease;
  background-color: var(--navy-500);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 11.5px),
    calc(100% - 11.5px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--blue-outlined .btn-span {
  color: var(--white);
}
body .dc-btn--blue-outlined img {
  display: none;
}
body .dc-btn--blue-outlined .dc-btn--icon-el {
  background-color: var(--white);
}
body .dc-btn--blue-outlined:hover {
  background: var(--navy-500);
}
body .dc-btn--blue-outlined:hover .btn-span,
body .dc-btn--blue-outlined:hover .btn-span a {
  color: var(--navy-500);
}
body .dc-btn--blue-outlined:hover .btn-bg {
  background: var(--white);
}
body .dc-btn--blue-outlined:hover .dc-btn--icon-el {
  background-color: var(--navy-500);
}
body .dc-btn--brand-outlined {
  background: var(--brand-bg);
}
body .dc-btn--brand-outlined .btn-bg {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: 0.5s ease;
  background-color: var(--white);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 11.5px),
    calc(100% - 11.5px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--brand-outlined .btn-span {
  color: var(--navy-500);
}
body .dc-btn--brand-outlined:hover .btn-span {
  color: var(--white);
}
body .dc-btn--brand-outlined:hover .btn-bg {
  background: var(--brand-bg);
}
body .dc-btn--brand-outlined:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(141deg) brightness(102%) contrast(101%);
}
body .dc-btn--brand-green-outlined {
  background: var(--green-500);
}
body .dc-btn--brand-green-outlined .btn-bg {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: 0.5s ease;
  background-color: var(--white);
  /*clip-path: polygon(
    0% 0,
    0 0%,
    0 0%,
    100% 0,
    100% calc(100% - 11.5px),
    calc(100% - 11.5px) 100%,
    0 100%,
    0 100%
  );*/
}
body .dc-btn--brand-green-outlined .btn-span {
  color: var(--navy-500);
}
body .dc-btn--brand-green-outlined:hover .btn-span {
  color: var(--navy-500);
}
body .dc-btn--brand-green-outlined:hover .btn-bg {
  background: var(--green-500);
}
body .dc-btn.dc-btn--small {
  padding: var(--dc-spacer-8) var(--dc-spacer-20);
  min-width: 94px;
  min-height: 41px;
}
body .dc-btn-play-video {
  position: absolute;
  z-index: 9;
  width: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transform: translateZ(0) scale(1.04) translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
}
@media all and (min-width: 769px) {
  body .dc-btn {
    min-width: 141px;
    width: auto;
    padding: var(--dc-spacer-18) var(--dc-spacer-40);
    min-height: 61px;
  }
  body .dc-btn.dc-btn--small {
    min-height: 41px;
  }
  body .dc-btn.dc-btn--full {
    width: 100%;
  }
}
body .knowledge-hub-wrapper .dc-btn {
  width: auto;
}
@media all and (min-width: 769px) {
  body .knowledge-hub-wrapper .dc-btn {
    min-width: 141px;
  }
}
body .hover-group {
  position: relative;
}
body .hover-group a {
  max-width: calc(100% - 28px);
}
body .hover-group .dc-arrow-link {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  -webkit-mask-image: url("../images/icons/no-image-link.svg");
  background-color: var(--navy-500);
}
body .hover-group .dc-arrow-link-bg {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  -webkit-mask-image: url("../images/icons/no-image-link.svg");
}
body .hover-group:hover .dc-arrow-link,
body .hover-group:hover .dc-arrow-link-bg {
  margin-left: 12px;
  transition: all 0.1s ease;
}
body .dubai-chambers-faq-categories-web #dc-category-filter-btn {
  width: auto;
}
body.has-edit-mode-menu .dc-btn--blue, body.has-edit-mode-menu .dc-btn--blue-outlined {
  display: block;
}
body .dc-title__dropzone .dc-btn__wrapper {
  padding: 0;
}
body .tag {
  width: 15px;
  aspect-ratio: 1;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
}
body .tag.digital-tag {
  background: var(--blue-500);
}
body .tag.commerce-tag {
  background: var(--red-500);
}
body .tag.international-tag {
  background: var(--green-500);
}
body .tag.news-tag {
  background: var(--navy-500);
}
body .icon-commerce {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--red-500);
}
body .icon-digital {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--blue-500);
}
body .icon-international {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--green-500);
}
body .dc-custom-bg {
  position: relative;
}
body .dc-custom-bg:before {
  right: 0;
  border-width: 0 0 64px 64px;
}
@media all and (max-width: 769px) {
  body .dc-custom-bg:before {
    border-width: 0 0 12px 12px;
  }
}
body .dc-custom-bg.bg-orange {
  /*&.bottom-corner {
    background: linear-gradient(-45deg, transparent 12px, var(--orange-500) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(-45deg, transparent 45px, var(--orange-500) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--orange-500) 0);
}
body .dc-custom-bg.bg-orange:before {
  border-color: transparent transparent var(--orange-500) transparent;
}
body .dc-custom-bg.bg-gray {
  /*&.bottom-corner {
    background: linear-gradient(-45deg, transparent 12px, var(--gray-100) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(-45deg, transparent 45px, var(--gray-100) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--gray-100) 0);
}
body .dc-custom-bg.bg-gray:before {
  border-color: transparent transparent var(--gray-100) transparent;
}
body .dc-custom-bg.bg-navy {
  /*&.bottom-corner {
    background: linear-gradient(-45deg, transparent 12px, var(--navy-500) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(-45deg, transparent 45px, var(--navy-500) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--navy-500) 0);
}
body .dc-custom-bg.bg-navy:before {
  border-color: transparent transparent var(--navy-500) transparent;
}
body .dc-custom-bg.bg-white {
  /*&.bottom-corner {
    background: linear-gradient(-45deg, transparent 12px, var(--white) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(-45deg, transparent 45px, var(--white) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--white) 0);
}
body .dc-custom-bg.bg-white:before {
  border-color: transparent transparent var(--white) transparent;
}
body .dc-custom-bg.bg-empty {
  background: transparent;
}
body .dc-custom-bg.bg-empty.bottom-corner {
  background: transparent;
}
body .dc-custom-bg.bg-empty:before {
  border-color: transparent;
}
body .dc-nav-arrow {
  aspect-ratio: 1;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  width: 14px;
  transform: rotate(-135deg);
  background: var(--navy-500);
  margin-top: 2px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  transition: transform 0.3s ease-out;
}
body .dc-nav-arrow.up {
  transform: rotate(45deg);
  margin-top: 8px;
}
body .dc-nav-arrow--white {
  background: var(--white);
}
body .dc-nav-arrow--down {
  transform: translate(-45deg);
}
body .dc-nav-arrow--up {
  transform: rotate(45deg);
}
body .dc-nav-arrow--left {
  transform: rotate(-45deg);
}
body .dc-nav-arrow--right {
  transform: rotate(-225deg);
}
body .dc-root-level .dc-nav-arrow {
  opacity: 0;
  display: block;
  transform: rotate(0deg);
  margin-bottom: 14px;
  margin-right: -5px;
  min-width: 16px;
}
body .dc-root-level .dc-nav-arrow--right {
  transform: rotate(-225deg);
  margin: 0;
}
body .dc-root-level.dc-root-level-hovered .dc-nav-arrow, body .dc-root-level.dc-selected .dc-nav-arrow {
  opacity: 1;
}
body .dc-component-form input[type=checkbox]::placeholder,
body .dubai-chambers-faq-categories-web input[type=checkbox]::placeholder,
body #dc-form-terms-and-conditions-section input[type=checkbox]::placeholder,
body #dc-government-official-non-profit-organization-section input[type=checkbox]::placeholder,
body .portlet-category-facet input[type=checkbox]::placeholder {
  color: var(--navy-500);
  font-family: "Inter", "Tajawal";
  font-size: var(--m-font-size-mobile);
  line-height: var(--m-line-height-mobile);
  letter-spacing: var(--m-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .dc-component-form input[type=checkbox]::placeholder,
  body .dubai-chambers-faq-categories-web input[type=checkbox]::placeholder,
  body #dc-form-terms-and-conditions-section input[type=checkbox]::placeholder,
  body #dc-government-official-non-profit-organization-section input[type=checkbox]::placeholder,
  body .portlet-category-facet input[type=checkbox]::placeholder {
    font-family: "Inter";
    font-size: var(--m-font-size);
    line-height: var(--m-line-height);
    letter-spacing: var(--m-letter-spacing);
  }
}
body .dc-component-form input[type=checkbox]:checked,
body .dc-component-form input[type=radio]:checked,
body .dubai-chambers-faq-categories-web input[type=checkbox]:checked,
body .dubai-chambers-faq-categories-web input[type=radio]:checked,
body #dc-form-terms-and-conditions-section input[type=checkbox]:checked,
body #dc-form-terms-and-conditions-section input[type=radio]:checked,
body #dc-government-official-non-profit-organization-section input[type=checkbox]:checked,
body #dc-government-official-non-profit-organization-section input[type=radio]:checked,
body .portlet-category-facet input[type=checkbox]:checked,
body .portlet-category-facet input[type=radio]:checked {
  background-color: var(--navy-500);
  background-size: var(--dc-spacer-10) var(--dc-spacer-10);
}
body .dc-component-form input[type=radio]:checked,
body .dubai-chambers-faq-categories-web input[type=radio]:checked,
body #dc-form-terms-and-conditions-section input[type=radio]:checked,
body #dc-government-official-non-profit-organization-section input[type=radio]:checked,
body .portlet-category-facet input[type=radio]:checked {
  background-size: var(--dc-spacer-16) var(--dc-spacer-16);
}
body .dc-main-form-wrapper .has-error .form-feedback-item {
  display: none;
}
body .ddm-form-builder-app .form-control {
  border: none;
  border-bottom: 1px solid var(--navy-500);
  padding: var(--dc-spacer-8) var(--dc-spacer-16);
  width: 629px;
  max-width: 100%;
  background-color: #fff !important;
}
body .ddm-form-builder-app .form-control.bg-light {
  background-color: inherit !important;
}
body .ddm-form-builder-app .form-control:focus, body .ddm-form-builder-app .form-control:active {
  box-shadow: none;
  outline: none;
}
body .ddm-form-builder-app .form-group,
body .ddm-form-builder-app .dc-field-helper {
  margin-bottom: var(--dc-spacer-24);
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper {
  display: inline;
  margin: 0 0 var(--dc-spacer-6) var(--dc-spacer-8);
  position: static;
}
@media (min-width: 769px) {
  body .ddm-form-builder-app .form-group .dc-tooltip-wrapper,
  body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper {
    position: relative;
  }
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper .dc-from-helper-text,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper .dc-from-helper-text {
  opacity: 0;
  position: absolute;
  display: block;
  padding: var(--dc-spacer-14);
  border: 1px solid var(--navy-500);
  color: var(--navy-500);
  background-color: var(--gray-50);
  width: 250px;
  z-index: 9;
  bottom: 130%;
  left: 50%;
}
@media (min-width: 769px) {
  body .ddm-form-builder-app .form-group .dc-tooltip-wrapper .dc-from-helper-text,
  body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper .dc-from-helper-text {
    bottom: 200%;
    transform: translate(-50%, 0);
  }
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper .dc-from-helper-text:before,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper .dc-from-helper-text:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid var(--navy-500);
  background-color: var(--gray-50);
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper .dc-from-helper-text:after,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper .dc-from-helper-text:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--gray-50);
  bottom: -7px;
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper svg,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper svg {
  display: inline;
  width: 18px;
  height: 18px;
  margin-top: -4px;
}
body .ddm-form-builder-app .form-group .dc-tooltip-wrapper svg:hover + .dc-from-helper-text,
body .ddm-form-builder-app .dc-field-helper .dc-tooltip-wrapper svg:hover + .dc-from-helper-text {
  opacity: 1;
  transform: translate(-50%, -10px);
  transition: all 0.3s ease;
}
body .ddm-form-builder-app .form-group.has-error .form-feedback-item,
body .ddm-form-builder-app .form-group.has-error .dc-form-feedback-item,
body .ddm-form-builder-app .dc-field-helper.has-error .form-feedback-item,
body .ddm-form-builder-app .dc-field-helper.has-error .dc-form-feedback-item {
  color: var(--red-500);
}
body .ddm-form-builder-app .form-group.has-error .form-feedback-item .inline-item-before,
body .ddm-form-builder-app .form-group.has-error .dc-form-feedback-item .inline-item-before,
body .ddm-form-builder-app .dc-field-helper.has-error .form-feedback-item .inline-item-before,
body .ddm-form-builder-app .dc-field-helper.has-error .dc-form-feedback-item .inline-item-before {
  margin: 0;
}
body .ddm-form-builder-app .form-group.has-error .form-feedback-item .lexicon-icon,
body .ddm-form-builder-app .form-group.has-error .dc-form-feedback-item .lexicon-icon,
body .ddm-form-builder-app .dc-field-helper.has-error .form-feedback-item .lexicon-icon,
body .ddm-form-builder-app .dc-field-helper.has-error .dc-form-feedback-item .lexicon-icon {
  display: none;
}
body .ddm-form-builder-app .dc-field-helper {
  margin-bottom: 0;
}
body .ddm-form-builder-app label {
  color: var(--navy-500);
  margin-bottom: var(--dc-spacer-8);
}
body .ddm-form-builder-app label .ddm-label-required {
  display: none;
}
body .ddm-form-builder-app .custom-control-label-text {
  margin-left: var(--dc-spacer-32);
  padding-left: 0;
  display: inline;
}
body .ddm-form-builder-app .ddm__radio div[data-checked=false].custom-radio {
  background-color: #fff;
}
body .ddm-form-builder-app .ddm__radio div[data-checked=true].custom-radio {
  background-color: var(--gray-50);
}
body .ddm-form-builder-app .ddm__radio div[data-checked=true].custom-radio .custom-control-label {
  font-weight: 700;
}
body .ddm-form-builder-app .ddm-field .select-button {
  display: none;
}
body .ddm-form-builder-app .custom-radio__group {
  max-width: 640px;
  margin: 0 auto;
}
body .ddm-form-builder-app .custom-radio,
body .ddm-form-builder-app .custom-checkbox {
  position: relative;
}
body .ddm-form-builder-app .custom-radio .custom-control-input ~ .custom-control-label:before, body .ddm-form-builder-app .custom-radio .custom-control-input + .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input ~ .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input + .custom-control-label:before {
  border: 1px solid var(--navy-500);
  background-color: var(--gray-50);
  border-radius: unset;
  position: absolute;
  top: 3px;
}
body .ddm-form-builder-app .custom-radio .custom-control-input ~ .custom-control-label:after, body .ddm-form-builder-app .custom-radio .custom-control-input + .custom-control-label:after,
body .ddm-form-builder-app .custom-checkbox .custom-control-input ~ .custom-control-label:after,
body .ddm-form-builder-app .custom-checkbox .custom-control-input + .custom-control-label:after {
  border-radius: unset;
  position: absolute;
  top: 3px;
}
body .ddm-form-builder-app .custom-radio .custom-control-input ~ .custom-control-label div, body .ddm-form-builder-app .custom-radio .custom-control-input + .custom-control-label div,
body .ddm-form-builder-app .custom-checkbox .custom-control-input ~ .custom-control-label div,
body .ddm-form-builder-app .custom-checkbox .custom-control-input + .custom-control-label div {
  padding-left: var(--dc-spacer-24);
}
body .ddm-form-builder-app .custom-radio .custom-control-input:checked ~ .custom-control-label, body .ddm-form-builder-app .custom-radio .custom-control-input:checked + .custom-control-label,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked ~ .custom-control-label,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked + .custom-control-label {
  background-color: var(--gray-50);
  border-color: var(--dc-navy-500);
}
body .ddm-form-builder-app .custom-radio .custom-control-input:checked ~ .custom-control-label:before, body .ddm-form-builder-app .custom-radio .custom-control-input:checked + .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked + .custom-control-label:before {
  background-color: var(--navy-500);
  border-color: var(--navy-500);
  color: #fff;
}
body .ddm-form-builder-app .custom-radio .custom-control-input:focus ~ .custom-control-label:before, body .ddm-form-builder-app .custom-radio .custom-control-input:focus + .custom-control-label, body .ddm-form-builder-app .custom-radio .custom-control-input:active ~ .custom-control-label:before, body .ddm-form-builder-app .custom-radio .custom-control-input:active + .custom-control-label,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:focus + .custom-control-label,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:active ~ .custom-control-label:before,
body .ddm-form-builder-app .custom-checkbox .custom-control-input:active + .custom-control-label {
  box-shadow: none;
}
body .ddm-form-builder-app .custom-radio label {
  padding: var(--dc-spacer-16);
  border: 2px solid var(--gray-300);
  display: block;
}
body .ddm-form-builder-app .custom-radio:not(:last-child) {
  margin-bottom: var(--dc-spacer-16);
}
body .ddm-form-builder-app .custom-radio .custom-control-input ~ .custom-control-label:before, body .ddm-form-builder-app .custom-radio .custom-control-input + .custom-control-label:before {
  border-radius: 50%;
  top: 21px;
  left: 16px;
}
body .ddm-form-builder-app .custom-radio .custom-control-input ~ .custom-control-label:after, body .ddm-form-builder-app .custom-radio .custom-control-input + .custom-control-label:after {
  border-radius: 50%;
  top: 21px;
  left: 16px;
}
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked ~ .custom-control-label, body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked + .custom-control-label {
  background-color: transparent;
}
body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after, body .ddm-form-builder-app .custom-checkbox .custom-control-input:checked + .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
}
body .ddm-form-builder-app .custom-checkbox svg {
  width: 18px;
  height: 18px;
  display: inline;
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item {
  margin-left: 0;
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn {
  margin-left: var(--dc-spacer-16);
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h5-font-size-mobile);
  line-height: var(--h5-line-height-mobile);
  padding: var(--dc-spacer-8) var(--dc-spacer-20);
  position: relative;
  transition: 0.5s ease-in-out;
  min-height: 41px;
  border-radius: 0;
}
@media all and (min-width: 769px) {
  body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn {
    font-family: "Inter Bold";
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary {
  background-color: var(--navy-500);
  color: var(--navy-500);
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  background-color: var(--white);
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:hover, body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:focus, body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:active {
  box-shadow: none;
  text-decoration: underline;
}
body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:hover:before, body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:focus:before, body .ddm-form-builder-app .liferay-ddm-form-field-document-library .input-group-item .btn.btn-secondary:active:before {
  background: var(--white);
}
body #dc-form-terms-and-conditions-section label,
body #dc-government-official-non-profit-organization-section label {
  margin-bottom: 0;
}
body .dc-accordion ol,
body .dc-accordion ul,
body .dc-rich-text ol,
body .dc-rich-text ul {
  padding-left: var(--dc-spacer-32);
}
body .dc-accordion ol,
body .dc-rich-text ol {
  list-style: decimal;
}
body .dc-accordion ul,
body .dc-rich-text ul {
  list-style: disc;
}
body .pagination-bar .pagination-results {
  color: var(--navy-500);
  font-family: "Inter", "Tajawal";
  font-size: var(--s-font-size-mobile);
  line-height: var(--s-line-height-mobile);
  letter-spacing: var(--s-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .pagination-bar .pagination-results {
    font-family: "Inter";
    font-size: var(--s-font-size);
    line-height: var(--s-line-height);
    letter-spacing: var(--s-letter-spacing);
  }
}
body .pagination-bar .page-item.active .page-link {
  color: var(--white);
  background-color: var(--navy-500);
  border-color: var(--navy-500);
}
body .pagination-bar .page-item.disabled .page-link {
  color: var(--gray-300);
}
body .pagination-bar .page-item .page-link {
  color: var(--navy-500);
  border-color: var(--navy-500);
  width: 40px;
  height: 40px;
  font-family: "Inter", "Tajawal";
  font-size: var(--s-font-size-mobile);
  line-height: var(--s-line-height-mobile);
  letter-spacing: var(--s-letter-spacing-mobile);
}
@media all and (min-width: 769px) {
  body .pagination-bar .page-item .page-link {
    font-family: "Inter";
    font-size: var(--s-font-size);
    line-height: var(--s-line-height);
    letter-spacing: var(--s-letter-spacing);
  }
}
body .pagination-bar .page-item .page-link:focus {
  box-shadow: 0 0 0 0.1rem var(--navy-500);
}
body .pagination-bar .page-item .page-link:active {
  background-color: var(--navy-500);
  color: var(--white);
}
body .pagination-bar .page-item .page-link:first-child {
  border-radius: 0;
}
body .pagination-bar .page-item .page-link:last-child {
  border-radius: 0;
}
body .dc-email-notification-wrapper .dc-subscribe-form-section {
  width: 100%;
}
@media all and (min-width: 769px) {
  body .dc-email-notification-wrapper .dc-subscribe-form-section {
    width: fit-content;
  }
}
body .dc-email-notification-wrapper .dc-email-subscription-form {
  display: flex;
  flex-direction: column;
}
body .dc-email-notification-wrapper .dc-email-subscription-form .dc-email-container input {
  height: 100%;
  width: 100%;
}
body .dc-email-notification-wrapper .dc-email-subscription-form .form-group-message {
  background-color: var(--white);
  width: 100%;
}
body .dc-email-notification-wrapper .dc-email-subscription-form .form-group-message--error {
  color: var(--red-500);
}
body .dc-email-notification-wrapper .dc-email-subscription-form .form-group-message--success {
  color: var(--green-500);
}
@media all and (min-width: 769px) {
  body .dc-email-notification-wrapper .dc-email-subscription-form .dc-email-container input {
    width: 294px;
  }
  body .dc-email-notification-wrapper .dc-email-subscription-form .form-group-message {
    width: 294px;
  }
}
body .dc-form-modal {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 92%;
}
@media (min-width: 769px) {
  body .dc-form-modal {
    width: 556px;
    padding: var(--dc-spacer-64) var(--dc-spacer-96);
  }
}
body .ddm-form-builder-app.container-fluid-max-xl {
  max-width: 100%;
}
body .ddm-form-builder-app .ddm-field-container {
  padding: 0;
}
body .ddm-form-builder-app .text-secondary {
  display: none;
}
body .ddm-form-builder-app .fields-group > .ddm-field {
  border: 2px solid var(--navy-500);
  padding: var(--dc-spacer-24) var(--dc-spacer-24) 0 var(--dc-spacer-24);
  margin-bottom: var(--dc-spacer-32);
}
@media (min-width: 769px) {
  body .ddm-form-builder-app .fields-group > .ddm-field {
    margin-bottom: var(--dc-spacer-48);
  }
}
body .ddm-form-builder-app .fields-group .ddm-field-types-fieldset__nested {
  margin-left: unset;
  margin-right: unset;
}
body .ddm-form-builder-app .fields-group .ddm-field-types-fieldset__nested > label:first-child {
  padding: var(--dc-spacer-24) var(--dc-spacer-10) var(--dc-spacer-24) 0;
  margin-bottom: var(--dc-spacer-48);
  border-bottom: 1px solid var(--gray-300);
  display: block;
}
body .ddm-form-builder-app .fields-group .ddm-field-types-fieldset__nested .ddm-field-container {
  padding: 0;
}
body .ddm-form-builder-app .fields-group .ddm-field-types-fieldset__nested-separator {
  display: none;
}
body .ddm-form-builder-app .fields-group .lfr-ddm-form-field-repeatable-toolbar {
  gap: var(--dc-spacer-10);
  position: absolute;
  top: 44px;
  right: 0;
  width: 74px;
}
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button,
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button {
  width: 32px;
  height: 32px;
  background-color: var(--navy-500);
  border-radius: unset !important;
  border: unset;
}
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button:hover, body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button:focus, body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button:active,
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button:hover,
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button:focus,
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button:active {
  background-color: var(--navy-500);
  box-shadow: none;
}
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button .lexicon-icon,
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button .lexicon-icon {
  width: 14px;
  height: 14px;
  margin-top: -3px;
  margin-bottom: unset;
}
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-delete-button {
  right: 42px;
}
body .ddm-form-builder-app .fields-group .ddm-form-field-repeatable-add-button {
  right: 0;
}
body .ddm-form-builder-app .lfr-ddm__default-page-header {
  @apply dc-hidden;
}
body .ddm-form-builder-app .lfr-ddm-form-container {
  @apply dc-pb-56 lg:dc-pb-96;
}
body .ddm-form-builder-app .lfr-ddm-form-page {
  @apply dc-max-w-640;
  margin: 0 auto;
}
body .ddm-form-builder-app .lfr-ddm-form-page-title {
  text-align: center;
  color: var(--navy-500);
  margin: var(--dc-spacer-56) auto var(--dc-spacer-32);
  padding: 0;
}
@media all and (min-width: 769px) {
  body .ddm-form-builder-app .lfr-ddm-form-page-title {
    margin: var(--dc-spacer-96) auto var(--dc-spacer-48);
  }
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls {
  text-align: center;
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls button {
  float: none !important;
  width: 100%;
}
@media (min-width: 376px) {
  body .ddm-form-builder-app .lfr-ddm-form-pagination-controls button {
    width: fit-content;
  }
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .lfr-ddm-form-pagination-prev {
  margin-right: 0 !important;
}
body .ddm-form-builder-app .ddm-form-pagination {
  padding-top: 56px;
}
@media all and (min-width: 769px) {
  body .ddm-form-builder-app .ddm-form-pagination {
    padding-top: 96px;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-nav {
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 0px 0 50px;
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-indicator {
  width: unset;
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-indicator {
    display: flex;
    flex-direction: row;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-indicator-label {
  color: var(--navy-500);
  text-overflow: unset;
  left: unset;
  overflow: visible;
  bottom: 24px;
  display: block;
  @apply dc-text-s dc-text-s-rtl;
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-indicator-label {
    position: static;
    transform: unset;
    margin-top: 0;
    order: 2;
    padding-left: 16px;
    max-width: 100%;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-icon[data-multi-step-icon] {
  @apply dc-bg-navy-500 dc-rounded-full;
  font-size: 12px;
  background-size: 14px;
  color: #fff;
  width: 20px;
  height: 20px;
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-icon[data-multi-step-icon] {
    order: 1;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-divider {
  background-color: var(--gray-200);
  height: 1px;
  top: 11px;
  margin: 0 var(--dc-spacer-16) 0 var(--dc-spacer-36);
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-divider {
    transform: rotate(90deg);
    left: -37px;
    top: 37px;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item .multi-step-icon {
  border: 1px solid var(--navy-500);
  background-color: var(--white);
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item .multi-step-icon:before {
  background-repeat: no-repeat;
  background-size: 14px;
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-item .multi-step-icon {
    border-color: var(--navy-500);
  }
}
@media (max-width: 768px) {
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-item {
    display: flex;
    flex-direction: column;
  }
  body .ddm-form-builder-app .ddm-form-pagination .multi-step-item:last-child .multi-step-divider {
    display: none;
  }
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.active .multi-step-divider {
  background-color: var(--gray-200);
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.active .multi-step-icon {
  color: transparent;
  background-position: center;
  background-color: var(--gray-300);
  border-color: var(--gray-300);
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.active .multi-step-icon:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M192.9%20429.5c-8.3%200-16.4-3.3-22.3-9.2L44.5%20294.1C15%20263.2%2062.7%20222%2089.1%20249.5L191.5%20352l230-258.9c27.2-30.5%2074.3%2011.5%2047.1%2041.9L216.4%20418.9c-5.8%206.5-14%2010.3-22.6%2010.6h-.9z'%20fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.complete .multi-step-icon {
  background-color: var(--navy-500);
  border-color: var(--navy-500);
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.complete:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M192.9%20429.5c-8.3%200-16.4-3.3-22.3-9.2L44.5%20294.1C15%20263.2%2062.7%20222%2089.1%20249.5L191.5%20352l230-258.9c27.2-30.5%2074.3%2011.5%2047.1%2041.9L216.4%20418.9c-5.8%206.5-14%2010.3-22.6%2010.6h-.9z'%20fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
}
body .ddm-form-builder-app .ddm-form-pagination .multi-step-item.complete .multi-step-indicator-label {
  width: calc(100% - 36px);
}
body .ddm-form-builder-app .ddm-field[data-field-name^=Select] .form-control-select {
  border-radius: 0;
  background-image: none;
  position: relative;
}
body .ddm-form-builder-app .ddm-field[data-field-name^=Select] .form-control-select:before {
  content: "";
  background-image: url("../images/icons/chevron-up.svg");
  background-size: 20px 10px;
  width: 20px;
  height: 10px;
  position: absolute;
  top: 18px;
  right: 12px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
body .ddm-form-builder-app .ddm-field[data-field-name^=Select] .form-control-select[aria-expanded=true]:before {
  transform: rotate(180deg);
}
@media all and (max-width: 658px) {
  body .ddm-form-builder-app .custom-radio__group {
    padding-left: var(--dc-spacer-16);
    padding-right: var(--dc-spacer-16);
  }
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls {
  padding: 0;
  @apply dc-max-w-640 dc-m-auto dc-flex dc-flex-wrap dc-gap-16 dc-justify-start lg:dc-justify-center;
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn {
  margin-bottom: 0;
  font-family: "Inter Bold", "Tajawal Bold";
  font-size: var(--h5-font-size-mobile);
  line-height: var(--h5-line-height-mobile);
  padding: var(--dc-spacer-8) var(--dc-spacer-20);
  position: relative;
  transition: 0.5s ease-in-out;
  height: 41px;
  border-radius: 0;
}
@media all and (min-width: 769px) {
  body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn {
    font-family: "Inter Bold";
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary {
  background-color: var(--navy-500);
  color: var(--navy-500);
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  background-color: var(--white);
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:hover, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:focus, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:active {
  box-shadow: none;
  text-decoration: underline;
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:hover:before, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:focus:before, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-secondary:active:before {
  background: var(--white);
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary {
  background: var(--navy-500);
  color: var(--white);
  border: none;
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  background-color: var(--navy-500);
  clip-path: polygon(0% 0, 0 0%, 0 0%, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%, 0 100%);
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary:hover, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary:active {
  color: var(--navy-500);
  box-shadow: none;
  background-color: var(--white);
}
body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary:hover:before, body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn.btn-primary:active:before {
  background: var(--white);
}
body .portlet-search-bar .input-group.search-bar-suggestions {
  min-width: 230px;
}
body .portlet-search-bar .input-group.search-bar-suggestions .input-group {
  align-items: center;
}
@media (min-width: 1025px) {
  body .portlet-search-bar .input-group.search-bar-suggestions {
    min-width: 282px;
  }
}
body .portlet-search-bar .input-group .input-group-item {
  border: 1px solid var(--navy-500);
}
body .portlet-search-bar .input-group .input-group-item input {
  order: 2;
  border-color: transparent;
  padding-left: 8px;
  font-size: var(--xs-font-size);
  color: var(--gray-500);
}
body .portlet-search-bar .input-group .input-group-item input:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}
body .portlet-search-bar .input-group .input-group-item input::placeholder {
  font-size: var(--xs-font-size);
  color: var(--gray-500);
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item {
  order: 1;
  position: relative;
  height: 100%;
  width: 30px;
  margin-left: 10px;
  padding: 0;
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn {
  position: relative;
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn svg {
  display: none;
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:before {
  position: absolute;
  content: "";
  background-image: url("/o/dubai-chamber-theme/images/icons/search.svg");
  background-position: no-repeat;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 9;
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:focus, body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:hover, body .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn.focus {
  box-shadow: unset;
}
body .portlet-search-bar .input-group .input-group-item .input-group-inset-item.input-group-inset-item-after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-color: transparent;
}
@media all and (min-width: 1440px) {
  body .portlet-search-bar .input-group.search-bar-suggestions {
    min-width: 282px;
    min-height: 44px;
    max-width: 684px;
  }
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu {
  border-color: var(--navy-500);
  border-radius: 0;
  margin: 0;
  padding: 0;
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu li, body .dropdown-menu.search-bar-suggestions-dropdown-menu .search-bar-suggestions-show-more {
  color: var(--navy-500);
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .search-bar-suggestions-show-more {
  padding: 12px;
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .search-bar-suggestions-results-list .suggestion-item-title {
  color: var(--navy-500);
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-subheader {
  padding: 12px;
  border-bottom: 1px solid var(--navy-500);
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .search-bar-suggestions-results-list > li:first-child {
  display: none;
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:hover, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item.hover, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:active {
  background: var(--navy-500);
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:hover .suggestion-item-title,
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:hover .suggestion-item-description, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:hover.search-bar-suggestions-show-more, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item.hover .suggestion-item-title,
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item.hover .suggestion-item-description, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item.hover.search-bar-suggestions-show-more, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:active .suggestion-item-title,
body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:active .suggestion-item-description, body .dropdown-menu.search-bar-suggestions-dropdown-menu .dropdown-item:active.search-bar-suggestions-show-more {
  color: var(--white);
  text-decoration: none;
}
body .dropdown-menu.search-bar-suggestions-dropdown-menu .list-unstyled li a {
  padding: 12px;
  border-bottom: 1px solid var(--navy-500);
}
body .portlet-search-results .search-total-label,
body .portlet-search-results .pagination-bar .pagination-items-per-page {
  display: none;
}
body .portlet-search-results .pagination-results {
  padding-left: 0;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group {
  padding: var(--dc-spacer-8) var(--dc-spacer-8) var(--dc-spacer-8) var(--dc-spacer-12);
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group div.dc-clean-search-bar {
  order: 2;
  background-image: url("/o/dubai-chamber-theme/images/icons/close-gray.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item * {
  display: inline-block;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item input {
  padding-left: 32px;
  font-family: "Inter", "Tajawal";
  font-size: var(--m-font-size-mobile);
  line-height: var(--m-line-height-mobile);
  letter-spacing: var(--m-letter-spacing-mobile);
  color: var(--black);
}
@media all and (min-width: 769px) {
  body .dc-search-bar .portlet-search-bar .input-group .input-group-item input {
    font-family: "Inter";
    font-size: var(--m-font-size);
    line-height: var(--m-line-height);
    letter-spacing: var(--m-letter-spacing);
  }
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item input::placeholder {
  font-family: "Inter", "Tajawal";
  font-size: var(--m-font-size-mobile);
  line-height: var(--m-line-height-mobile);
  letter-spacing: var(--m-letter-spacing-mobile);
  color: var(--black);
}
@media all and (min-width: 769px) {
  body .dc-search-bar .portlet-search-bar .input-group .input-group-item input::placeholder {
    font-family: "Inter";
    font-size: var(--m-font-size);
    line-height: var(--m-line-height);
    letter-spacing: var(--m-letter-spacing);
  }
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item {
  margin-left: 24px;
  border-radius: 0;
  border: none;
  width: 65px;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn {
  background-color: var(--navy-500);
  color: var(--white);
  padding: var(--dc-spacer-6) var(--dc-spacer-10);
  margin: 0;
  width: 100%;
  height: 36px;
  border-radius: 0;
  white-space: nowrap;
  position: unset;
  font-family: "Inter Medium", "Tajawal Medium";
  font-size: var(--xs-font-size-mobile);
  line-height: var(--xs-line-height-mobile);
}
@media all and (min-width: 769px) {
  body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn {
    font-family: "Inter Medium";
    font-size: var(--xs-font-size);
    line-height: var(--xs-line-height);
  }
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn svg {
  display: none;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:before {
  content: "Search";
  display: inline-block;
  color: var(--white);
  position: unset;
  background-image: unset;
  background-position: unset;
  width: unset;
  height: unset;
  transform: unset;
  top: unset;
  left: unset;
  z-index: unset;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:hover:before {
  color: var(--navy-500);
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:hover, body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:focus {
  box-shadow: none;
  background-color: var(--white);
  color: var(--navy-500);
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn svg {
  display: none;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item:after {
  position: absolute;
  content: "";
  background-image: url("/o/dubai-chamber-theme/images/icons/search-bar-icon.svg");
  background-position: no-repeat;
  width: 24px;
  height: 24px;
  transform: translate(0, -50%);
  top: 50%;
  left: var(--dc-spacer-12);
  z-index: 9;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item {
  order: 3;
}
body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item:before {
  display: none;
}
body .dc-search-empty-result {
  max-width: 600px;
  margin: 0 auto;
}
body .no-image-card h5 a {
  text-underline-offset: unset;
}
body .no-image-card h5 a:hover, body .no-image-card h5 a:focus {
  text-decoration: none;
  color: var(--navy-500);
}
body .dc-underline:hover,
body .dc-underline a:hover {
  color: var(--navy-500);
}
body .card-title-link a {
  text-underline-offset: unset;
}
body .card-title-link a:hover, body .card-title-link a:focus {
  text-decoration: none;
}
body .dc-border-white {
  border-color: rgb(255, 255, 255) !important;
}
body .parallax-no-margin {
  margin-top: 0 !important;
}
body .dc-blur {
  filter: blur(6px);
  position: relative;
  overflow-x: hidden;
}
body .dc-blur:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: calc(100% + 500px);
  content: "";
  z-index: 986;
  background-color: var(--navy-500);
  opacity: 0.5;
}
body .dc-brand-bg {
  background-color: var(--gray-200);
}
@media (max-width: 1024px) {
  body .dc-brand-bg {
    background-color: var(--brand-bg);
  }
}
body .dc-brand-bg .dc-dubai-chambers {
  background-color: var(--navy-500);
}
body .dc-brand-bg .dc-dubai-chamber-of-commerce {
  background-color: var(--red-500);
}
body .dc-brand-bg .dc-dubai-chamber-of-digital-economy {
  background-color: var(--blue-500);
}
body .dc-brand-bg .dc-dubai-international-chamber {
  background-color: var(--green-500);
}
@media (max-width: 1024px) {
  body .js-accessibility-container img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(68deg) brightness(106%) contrast(105%);
  }
}
body .dc-text-lang-ar {
  font-family: "Tajawal Medium";
  font-size: var(--s-font-size);
  line-height: var(--s-line-height);
  letter-spacing: var(--s-letter-spacing);
}
body .dc-text-lang-eng {
  font-family: "Inter Medium";
  font-size: var(--xs-font-size);
  line-height: var(--xs-line-height);
}

/*body,
a, a:link, a:visited, a:focus, a:active, a:any-link,
button:hover, button:visited, button:focus, button:active, button:valid, button:focus-within, button:focus-visible,
input:hover,
label:hover,
.dc-nav-item, .dc-nav-arrow, .dc-treeview-link, .category-item button, .custom-checkbox .custom-control-label, .custom-checkbox .custom-control-label-text, .custom-checkbox input, .facet-value button,  {
  cursor: url('../images/icons/cursor.svg'), auto;
}*/
/*body {
  a:hover, .dc-nav-item:hover, .dc-nav-arrow:hover, .dc-gallery-tab:hover, .dc-btn-play-video:hover, .dc-treeview-link:hover, .category-item:hover button, , .custom-checkbox:hover.custom-control-label, .custom-checkbox:hover .custom-control-label-text .custom-checkbox:hover input, .facet-value:hover button, .js-happiness-meter-icon:hover, .openChatbox:hover {
    cursor: url('../images/icons/cursor-hover.svg'), auto;
    transition: all 0.1s ease;
  }
}*/
/*.card-image {
  cursor: none;
  &:hover {
    cursor: none;
  }
}*/
body.has-edit-mode-menu .dc-fade-in,
body.has-edit-mode-menu .dc-fade-in-columns,
.page-editor__fragment-content .dc-fade-in,
.page-editor__fragment-content .dc-fade-in-columns,
.page-editor__fragment-content .no-image-card,
.page-editor__fragment-content .card-animation {
  opacity: 1;
}

.dc-fade-in, .dc-fade-in-columns, .no-image-card, .card-animation {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.dc-visible {
  opacity: 1;
  transform: translateY(0);
}

body:not(.has-edit-mode-menu) .portlet {
  position: static;
  margin-bottom: 0;
}
body:not(.has-edit-mode-menu) .dc-portlet-tech-search-bar .portlet {
  position: relative;
}
body:not(.has-edit-mode-menu) .dc-wrapper-layout {
  opacity: 0;
}
@media all and (max-width: 575px) {
  body:not(#__):not(#___) .cadmin.control-menu-container {
    position: sticky;
  }
}

html.rtl body {
  font-family: "Tajawal", "Inter";
  /*------------ Liferay form -----------*/
  /*.dc-bottom-corner-sm {
    clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% 100%, 100% 100%, 14px 100%, 0 calc(100% - 14px));
  }

  .dc-bottom-corner-md {
    clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% 100%, 100% 100%, 32px 100%, 0 calc(100% - 32px));
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% 100%, 100% 100%, 14px 100%, 0 calc(100% - 14px));
    }
  }

  .dc-bottom-corner-xl {
    clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% 100%, 100% 100%, 64px 100%, 0 calc(100% - 64px));
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% 100%, 100% 100%, 14px 100%, 0 calc(100% - 14px));
    }
  }

  .dc-top-corner-sm {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  0 100%, 0 100%);
  }

  .dc-top-corner-md {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 32px) 0%, 100% 32px, 100% 100%, 100% 100%,  0 100%, 0 100%);
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  0 100%, 0 100%);
    }
  }

  .dc-diagonal-corners-sm {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  14px 100%, 0 calc(100% - 14px));
  }

  .dc-diagonal-corners-md {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 32px) 0%, 100% 32px, 100% 100%, 100% 100%,  32px 100%, 0 calc(100% - 32px));
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, calc(100% - 14px) 0%, 100% 14px, 100% 100%, 100% 100%,  14px 100%, 0 calc(100% - 14px));
    }
  }

  .dc-diagonal-corners-xl {
    clip-path: polygon(0% 0, 0 0%, calc(100% - 64px) 0%, 100% 64px, 100% 100%, 100% 100%,  64px 100%, 0 calc(100% - 64px));
    @media all and (max-width: $lg) {
      clip-path: polygon(0% 0, 0 0%, calc(100% - 32px) 0%, 100% 32px, 100% 100%, 100% 100%,  32px 100%, 0 calc(100% - 32px));
    }
  }

  .cursor-hover {
    clip-path: polygon(50% 0%, 50% 35%, 35% 50%, 50% 65%, 50% 100%, 0% 50%);
    img {
      left: 59px;
      transform: rotate(180deg);
    }
  }*/
}
html.rtl body .dc-rich-text h1 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h1-font-size-rtl-mobile);
  line-height: var(--h1-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-rich-text h1 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h1-font-size-rtl);
    line-height: var(--h1-line-height-rtl);
  }
}
html.rtl body .dc-rich-text h2 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h2-font-size-rtl-mobile);
  line-height: var(--h2-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-rich-text h2 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h2-font-size-rtl);
    line-height: var(--h2-line-height-rtl);
  }
}
html.rtl body .dc-rich-text h3 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h3-font-size-rtl-mobile);
  line-height: var(--h3-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-rich-text h3 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h3-font-size-rtl);
    line-height: var(--h3-line-height-rtl);
  }
}
html.rtl body .dc-rich-text h4 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h4-font-size-rtl-mobile);
  line-height: var(--h4-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-rich-text h4 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h4-font-size-rtl);
    line-height: var(--h4-line-height-rtl);
  }
}
html.rtl body .dc-h1-rtl {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h1-font-size-rtl-mobile);
  line-height: var(--h1-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h1-rtl {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h1-font-size-rtl);
    line-height: var(--h1-line-height-rtl);
  }
}
html.rtl body .dc-h2-rtl,
html.rtl body .dc-accordion h1 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h2-font-size-rtl-mobile);
  line-height: var(--h2-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h2-rtl,
  html.rtl body .dc-accordion h1 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h2-font-size-rtl);
    line-height: var(--h2-line-height-rtl);
  }
}
html.rtl body .dc-h3-rtl,
html.rtl body .dc-accordion h2 {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h3-font-size-rtl-mobile);
  line-height: var(--h3-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h3-rtl,
  html.rtl body .dc-accordion h2 {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h3-font-size-rtl);
    line-height: var(--h3-line-height-rtl);
  }
}
html.rtl body .dc-h4-rtl,
html.rtl body .dc-accordion h3,
html.rtl body .portlet-forms .lfr-ddm-form-page-title {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h4-font-size-rtl-mobile);
  line-height: var(--h4-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h4-rtl,
  html.rtl body .dc-accordion h3,
  html.rtl body .portlet-forms .lfr-ddm-form-page-title {
    font-family: "Tajawal Bold", "Inter Bold";
    font-size: var(--h4-font-size-rtl);
    line-height: var(--h4-line-height-rtl);
  }
}
html.rtl body .dc-h5-rtl,
html.rtl body .dc-accordion h4,
html.rtl body .portlet-forms .ddm-field-types-fieldset__nested > label:first-child {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h5-font-size-rtl-mobile);
  line-height: var(--h5-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h5-rtl,
  html.rtl body .dc-accordion h4,
  html.rtl body .portlet-forms .ddm-field-types-fieldset__nested > label:first-child {
    font-family: "Tajawal Extrabold", "Inter Bold";
    font-size: var(--h5-font-size-rtl);
    line-height: var(--h5-line-height-rtl);
  }
}
html.rtl body .dc-h6-rtl,
html.rtl body .portlet-forms .ddm-label,
html.rtl body .portlet-forms .lfr-ddm-legend,
html.rtl body .dc-group-label,
html.rtl body .portlet-forms .form-group.has-error .form-feedback-item,
html.rtl body .portlet-forms .form-group.has-error .dc-form-feedback-item,
html.rtl body .dc-terms-and-conditions-error,
html.rtl body .dc-treeview-link.active span {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h6-font-size-rtl-mobile);
  line-height: var(--h6-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-h6-rtl,
  html.rtl body .portlet-forms .ddm-label,
  html.rtl body .portlet-forms .lfr-ddm-legend,
  html.rtl body .dc-group-label,
  html.rtl body .portlet-forms .form-group.has-error .form-feedback-item,
  html.rtl body .portlet-forms .form-group.has-error .dc-form-feedback-item,
  html.rtl body .dc-terms-and-conditions-error,
  html.rtl body .dc-treeview-link.active span {
    font-family: "Tajawal Extrabold", "Inter Bold";
    font-size: var(--h6-font-size-rtl);
    line-height: var(--h6-line-height-rtl);
  }
}
html.rtl body .dc-h7-rtl {
  font-family: "Tajawal Medium", "Inter Medium";
  font-size: 16px;
  line-height: 19.6px;
  letter-spacing: -0.28px;
}
html.rtl body .dc-text-xs-rtl {
  font-family: "Tajawal Medium", "Inter Medium";
  font-size: var(--xs-font-size-rtl-mobile);
  line-height: var(--xs-line-height-rtl-mobile);
  letter-spacing: var(--xs-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-text-xs-rtl {
    font-family: "Tajawal Medium", "Inter Medium";
    font-size: var(--xs-font-size-rtl);
    line-height: var(--xs-line-height-rtl);
    letter-spacing: var(--xs-letter-spacing-rtl);
  }
}
html.rtl body .dc-text-s-rtl,
html.rtl body .portlet-forms label .custom-control-label,
html.rtl body .portlet-forms .form-group .form-feedback-group,
html.rtl body .dc-treeview-link span {
  font-family: "Tajawal", "Inter";
  font-size: var(--s-font-size-rtl-mobile);
  line-height: var(--s-line-height-rtl-mobile);
  letter-spacing: var(--s-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-text-s-rtl,
  html.rtl body .portlet-forms label .custom-control-label,
  html.rtl body .portlet-forms .form-group .form-feedback-group,
  html.rtl body .dc-treeview-link span {
    font-family: "Tajawal Medium", "Inter Medium";
    font-size: var(--s-font-size-rtl);
    line-height: var(--s-line-height-rtl);
    letter-spacing: var(--s-letter-spacing-rtl);
  }
}
html.rtl body .dc-text-m-rtl,
html.rtl body .dc-accordion p,
html.rtl body .dc-accordion a,
html.rtl body .dc-accordion span,
html.rtl body .dc-accordion li,
html.rtl body .dc-accordion cite,
html.rtl body .dc-accordion em,
html.rtl body .dc-accordion u,
html.rtl body .dc-accordion strong,
html.rtl body .dc-accordion pre,
html.rtl body .dc-rich-text p,
html.rtl body .dc-rich-text a,
html.rtl body .dc-rich-text span,
html.rtl body .dc-rich-text li,
html.rtl body .dc-rich-text cite,
html.rtl body .dc-rich-text em,
html.rtl body .dc-rich-text u,
html.rtl body .dc-rich-text strong,
html.rtl body .dc-rich-text pre {
  font-family: "Tajawal", "Inter";
  font-size: var(--m-font-size-rtl-mobile);
  line-height: var(--m-line-height-rtl-mobile);
  letter-spacing: var(--m-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-text-m-rtl,
  html.rtl body .dc-accordion p,
  html.rtl body .dc-accordion a,
  html.rtl body .dc-accordion span,
  html.rtl body .dc-accordion li,
  html.rtl body .dc-accordion cite,
  html.rtl body .dc-accordion em,
  html.rtl body .dc-accordion u,
  html.rtl body .dc-accordion strong,
  html.rtl body .dc-accordion pre,
  html.rtl body .dc-rich-text p,
  html.rtl body .dc-rich-text a,
  html.rtl body .dc-rich-text span,
  html.rtl body .dc-rich-text li,
  html.rtl body .dc-rich-text cite,
  html.rtl body .dc-rich-text em,
  html.rtl body .dc-rich-text u,
  html.rtl body .dc-rich-text strong,
  html.rtl body .dc-rich-text pre {
    font-family: "Tajawal", "Inter";
    font-size: var(--m-font-size-rtl);
    line-height: var(--m-line-height-rtl);
    letter-spacing: var(--m-letter-spacing-rtl);
  }
}
html.rtl body .dc-text-l-rtl {
  font-family: "Tajawal", "Inter";
  font-size: var(--l-font-size-rtl-mobile);
  line-height: var(--l-line-height-rtl-mobile);
  letter-spacing: var(--l-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-text-l-rtl {
    font-family: "Tajawal", "Inter";
    font-size: var(--l-font-size-rtl);
    line-height: var(--l-line-height-rtl);
    letter-spacing: var(--l-letter-spacing-rtl);
  }
}
html.rtl body .dc-text-xl-rtl {
  font-family: "Tajawal", "Inter";
  font-size: var(--xl-font-size-rtl-mobile);
  line-height: var(--xl-line-height-rtl-mobile);
  letter-spacing: var(--xl-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-text-xl-rtl {
    font-family: "Tajawal", "Inter";
    font-size: var(--xl-font-size-rtl);
    line-height: var(--xl-line-height-rtl);
    letter-spacing: var(--xl-letter-spacing-rtl);
  }
}
html.rtl body .dc-accordion a {
  text-decoration: underline;
}
html.rtl body .dc-btn {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h5-font-size-rtl-mobile);
  line-height: var(--h5-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-btn {
    font-family: "Tajawal Extrabold", "Inter Bold";
    font-size: var(--h5-font-size-rtl);
    line-height: var(--h5-line-height-rtl);
  }
}
html.rtl body .dc-btn--white:hover {
  color: var(--white);
}
html.rtl body .dc-btn--blue:hover {
  color: var(--navy-500);
}
html.rtl body .hover-group .dc-arrow-link,
html.rtl body .hover-group .dc-arrow-link-bg {
  transform: rotate(180deg);
}
html.rtl body .tag {
  width: 15px;
  aspect-ratio: 1;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  transform: rotate(90deg);
}
html.rtl body .tag.digital-tag {
  background: var(--blue-500);
}
html.rtl body .tag.commerce-tag {
  background: var(--red-500);
}
html.rtl body .tag.international-tag {
  background: var(--green-500);
}
html.rtl body .tag.news-tag {
  background: var(--navy-500);
}
html.rtl body .icon-commerce {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--red-500);
}
html.rtl body .icon-digital {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--blue-500);
}
html.rtl body .icon-international {
  width: 118.75px;
  aspect-ratio: 1;
  margin-top: 12px;
  clip-path: polygon(65% 35%, 35% 35%, 35% 65%, 0 100%, 0 0, 100% 0);
  background: var(--green-500);
}
html.rtl body .dc-custom-bg {
  position: relative;
}
html.rtl body .dc-custom-bg.bg-orange {
  /*&.bottom-corner {
    background: linear-gradient(45deg, transparent 12px, var(--orange-500) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(45deg, transparent 45px, var(--orange-500) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--orange-500) 0);
}
html.rtl body .dc-custom-bg.bg-orange:before {
  border-color: transparent transparent var(--orange-500) transparent;
}
html.rtl body .dc-custom-bg.bg-gray {
  /*&.bottom-corner {
    background: linear-gradient(45deg, transparent 12px, var(--gray-100) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(45deg, transparent 45px, var(--gray-100) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--gray-100) 0);
}
html.rtl body .dc-custom-bg.bg-gray:before {
  border-color: transparent transparent var(--gray-100) transparent;
}
html.rtl body .dc-custom-bg.bg-navy {
  /*&.bottom-corner {
    background: linear-gradient(45deg, transparent 12px, var(--navy-500) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(45deg, transparent 45px, var(--navy-500) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--navy-500) 0);
}
html.rtl body .dc-custom-bg.bg-navy:before {
  border-color: transparent transparent var(--navy-500) transparent;
}
html.rtl body .dc-custom-bg.bg-white {
  /*&.bottom-corner {
    background: linear-gradient(45deg, transparent 12px, var(--white) 0);
    @media all and (min-width: $lg) {
      background: linear-gradient(45deg, transparent 45px, var(--white) 0);
    }
  }*/
  background: linear-gradient(0deg, transparent 0px, var(--white) 0);
}
html.rtl body .dc-custom-bg.bg-white:before {
  border-color: transparent transparent var(--white) transparent;
}
html.rtl body .dc-custom-bg.bg-empty {
  background: transparent;
}
html.rtl body .dc-custom-bg.bg-empty.bottom-corner {
  background: transparent;
}
html.rtl body .dc-custom-bg.bg-empty:before {
  border-color: transparent;
}
html.rtl body .dubai-chamber-theme.rtl .dc-custom-bg:before {
  border-width: 0 64px 64px 0;
  left: 0;
}
@media all and (max-width: 769px) {
  html.rtl body .dubai-chamber-theme.rtl .dc-custom-bg:before {
    border-width: 0 12px 12px 0;
  }
}
html.rtl body .dc-root-level .dc-cta-nav-item {
  flex-direction: row-reverse;
}
html.rtl body .dc-root-level .dc-nav-arrow {
  margin-right: -5px !important;
}
html.rtl body .dc-root-level .dc-mega-menu__inner .dc-nav-arrow--right {
  transform: rotate(-45deg);
}
html.rtl body .pagination-bar .pagination-results {
  font-family: "Tajawal", "Inter";
  font-size: var(--s-font-size-rtl-mobile);
  line-height: var(--s-line-height-rtl-mobile);
  letter-spacing: var(--s-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .pagination-bar .pagination-results {
    font-family: "Tajawal Medium", "Inter Medium";
    font-size: var(--s-font-size-rtl);
    line-height: var(--s-line-height-rtl);
    letter-spacing: var(--s-letter-spacing-rtl);
  }
}
html.rtl body .pagination-bar .page-item .page-link svg {
  transform: rotate(180deg);
}
html.rtl body .dc-accordion ol,
html.rtl body .dc-rich-text ol {
  list-style: arabic-indic;
}
html.rtl body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn {
  font-family: "Tajawal Bold", "Inter Bold";
  font-size: var(--h5-font-size-rtl-mobile);
  line-height: var(--h5-line-height-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .ddm-form-builder-app .lfr-ddm-form-pagination-controls .btn {
    font-family: "Tajawal Extrabold", "Inter Bold";
    font-size: var(--h5-font-size-rtl);
    line-height: var(--h5-line-height-rtl);
  }
}
html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item input {
  font-family: "Tajawal", "Inter";
  font-size: var(--m-font-size-rtl-mobile);
  line-height: var(--m-line-height-rtl-mobile);
  letter-spacing: var(--m-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item input {
    font-family: "Tajawal", "Inter";
    font-size: var(--m-font-size-rtl);
    line-height: var(--m-line-height-rtl);
    letter-spacing: var(--m-letter-spacing-rtl);
  }
}
html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item input::placeholder {
  font-family: "Tajawal", "Inter";
  font-size: var(--m-font-size-rtl-mobile);
  line-height: var(--m-line-height-rtl-mobile);
  letter-spacing: var(--m-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item input::placeholder {
    font-family: "Tajawal", "Inter";
    font-size: var(--m-font-size-rtl);
    line-height: var(--m-line-height-rtl);
    letter-spacing: var(--m-letter-spacing-rtl);
  }
}
html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn {
  font-family: "Tajawal Medium", "Inter Medium";
  font-size: var(--xs-font-size-rtl-mobile);
  line-height: var(--xs-line-height-rtl-mobile);
  letter-spacing: var(--xs-letter-spacing-rtl-mobile);
}
@media all and (min-width: 769px) {
  html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn {
    font-family: "Tajawal Medium", "Inter Medium";
    font-size: var(--xs-font-size-rtl);
    line-height: var(--xs-line-height-rtl);
    letter-spacing: var(--xs-letter-spacing-rtl);
  }
}
html.rtl body .dc-search-bar .portlet-search-bar .input-group .input-group-item .input-group-inset-item .btn:before {
  content: "بحث";
}
html.rtl body .search-facet .collapse-icon .collapse-icon-closed,
html.rtl body .search-facet .collapse-icon .collapse-icon-open {
  right: 0;
  left: auto;
}

/*# sourceMappingURL=main.css.map */